<template>
  <div
    class="course-detail-single"
    id="course-detail-single"
    v-if="!!goodsDetail"
  >
    <div class="index-page">
      <div class="course-info-box">
        <CourseInfo :goodsDetail="goodsDetail" />
      </div>
      <div class="subject-info-box" v-if="goodsDetail.sellType === 1">
        <SubjectInfo :goodsDetail="goodsDetail" />
      </div>
      <div class="tabs-info-box">
        <TabsInfo :goodsDetail="goodsDetail" />
      </div>
    </div>
    <fouter class="fouter_bot"></fouter>
  </div>
</template>

<script>
// @ is an alias to /src
import CourseInfo from "@/components/CourseInfo.vue";
import SubjectInfo from "@/components/SubjectInfo.vue";
import TabsInfo from "@/components/TabsInfo.vue";
import fouter from '../../src/components/fouter/fouter'
export default {
  name: "CourseDetailSingle",
  data() {
    return {
      msg: "CourseDetailSingle  课程详情——单课程",
      goodsDetail: null
    };
  },
  methods: {
    getGoodsDetail() {
      // 获取当前分类的课程列表
      let ref = this;
      // let paramData = {
      //   ...ref.$route.query
      // };
      // ref.axios.post("/shopApi/get/goods/info", paramData).then(res => {
      //   let data = res.data;
      //   if (res.data.success) {
      //     // data
      //     ref.goodsDetail = data.entity.goodsInfo;
      //     console.log(ref.goodsDetail,'..........')
      //   }
      // });
      let paramData = {
        ...ref.$route.query,
        userId : ref.$store.state.userInfo.userId
      };
      ref.axios.post("/shop1Api/get/goods/info", paramData).then(res => {
        let data = res.data;
        if (res.data.success) {
          // data
          ref.goodsDetail = data.entity.goodsInfo;
          console.log(ref.goodsDetail,'..........')
        }
      });
    }
  },
  created() {
    this.getGoodsDetail();
  },
  beforeRouteUpdate(to, from, next) {
    this.goodsDetail = null;
    this.$nextTick(() => {
      next();
      this.getGoodsDetail();
    });
  },
  components: {
    CourseInfo,
    SubjectInfo,
    TabsInfo,
    fouter
  }
};
</script>
<style lang="less" scoped>
html body{
  height: 100%;
}
.course-detail-single {
  .index-page{
    min-height: calc(100vh - 160px);
    .course-info-box {
      padding: 20px;
      background-color: #fff;
      margin-top: 57px;
    }
    .subject-info-box {
      padding: 20px 0;
    }
    .tabs-info-box {
      padding: 20px 0;
    }
  }
  .fouter_bot{
    width: 100%;
    height: 160px;
  }
}
</style>
