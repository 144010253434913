<template>
  <div class="course-card" :class="{ 'course-card-small': isSmall }">
    <div class="box-card-course-warp">
      <el-card :class="adaptive === 'Pc' ? 'box-card-course' : 'box-card-course'" shadow="hover">
        <div slot="header" class="clearfix">
          <div class="clearfix_explain" :title="courseData.name">{{ courseData.name }}</div>
          <el-tag
            class="hidden-xs-only"
            size="small"
            effect="dark"
            style="float: right;"
            type="text"
            >{{ courseData.professionName }}</el-tag
          >
        </div>
        <div class="goods-content">
          <p class="course-times">{{ courseData.labels }}</p>
          <div class="course-teachers">
            <el-card
              class="box-card-teacher"
              shadow="never"
              v-for="(itemTeacher, indexTeacher) in courseData.teacherList"
              :key="indexTeacher"
            >
              <img
                :src="
                  'https://yd-kc-img.oss-cn-beijing.aliyuncs.com' +
                    itemTeacher.photo
                "
                class="teacher-img"
              />
              <div class="teacher-name">
                <span>{{ itemTeacher.name }}</span>
              </div>
            </el-card>
          </div>
          <div class="number-price">
            <div class="course-number">
              已售:{{ courseData.studyNum + "\u3000\u3000" }}限售:{{
                courseData.limitSellNum
              }}
            </div>
            <div
              class="course-price"
              :class="{ 'is-free': !courseData.lablePrice }"
            >
              {{ getPrice(courseData) }}
            </div>
          </div>
        </div>
      </el-card>
    </div>
  </div>
</template>
<script>
// import signIn from "@/views/SignIn.vue";
export default {
  name: "CourseCard",
  props: {
    courseData: Object
  },
  data() {
    return {
      msg: "course-card 课程卡片",
      isSmall: false, //是否小屏
      //适配类型
      adaptive: ''
    };
  },
  computed: {},
  methods: {
    getPrice(item) {
      let priceText = "";
      if (item.labelPrice) {
        priceText = `￥${item.labelPrice}`;
      } else {
        priceText = "免费";
      }
      return priceText;
    }
  },
  created() {},
  mounted() {
    this.adaptive = this.$store.state.adaptive
    let w = document.documentElement.offsetWidth || document.body.offsetWidth;
    if (w < 768) {
      this.isSmall = true;
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.course-card {
  p {
    margin: 0;
    padding: 0;
  }
  .box-card-course {
    width: 370px;
    //height: 220px;
    margin-bottom: 20px;
    .clearfix{
      display: flex;
      flex-direction: row;
      .clearfix_explain{
        width: 256px;
        font-size: 18px;
        font-family: Adobe Heiti Std;
        font-weight: normal;
        color: #151515;
        line-height: 20px;
        margin-right: 24px;
        overflow: hidden;/*超出部分隐藏*/
        text-overflow:ellipsis;/* 超出部分显示省略号 */
        white-space: nowrap;/*规定段落中的文本不进行换行 */
      }
    }
    .goods-content {
      .course-times {
        color: grey;
      }
      .course-teachers {
        display: flex;
        .box-card-teacher {
          width: 100px;
          height: 140px;
          border: none;
          .teacher-img {
            width: 60px;
            height: 60px;
          }
          .teacher-name {
            text-align: center;
          }
        }
      }
      .number-price {
        display: flex;
        justify-content: space-between;
        .course-number {
          color: grey;
        }
        .course-price {
          color: red;
          font-size: 20px;
          &.is-free {
            font-size: 16px;
          }
        }
      }
    }
  }
  .box-card-course-model {
    width: 3.7rem;
    //height: 220px;
    margin-bottom: 0.2rem;
    .clearfix{
      display: flex;
      flex-direction: row;
      .clearfix_explain{
        width: 2.56rem;
        font-size: 0.18rem;
        font-family: Adobe Heiti Std;
        font-weight: normal;
        color: #151515;
        line-height: 0.2rem;
        margin-right: 0.24rem;
        overflow: hidden;/*超出部分隐藏*/
        text-overflow:ellipsis;/* 超出部分显示省略号 */
        white-space: nowrap;/*规定段落中的文本不进行换行 */
      }
    }
    .goods-content {
      .course-times {
        color: grey;
      }
      .course-teachers {
        display: flex;
        .box-card-teacher {
          width: 1rem;
          height: 1.4rem;
          border: none;
          .teacher-img {
            width: 0.6rem;
            height: 0.6rem;
          }
          .teacher-name {
            text-align: center;
          }
        }
      }
      .number-price {
        display: flex;
        justify-content: space-between;
        .course-number {
          color: grey;
        }
        .course-price {
          color: red;
          font-size: 0.2rem;
          &.is-free {
            font-size: 0.16rem;
          }
        }
      }
    }
  }
  // 小屏幕
  &.course-card-small {
    width: 100%;
    .box-card-course {
      width: 100%;
      height: 280px;
    }
  }
}
</style>
