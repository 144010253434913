<template>
  <div :class="adaptive === 'Pc' ? 'subject-info' : 'subject-info-model'" :id="adaptive === 'Pc' ? 'subject-info' : 'subject-info-model'">
    <el-card class="subject-info-content">
      <div slot="header" class="discount-head">
        <span class="discount-title">联报优惠套餐</span>
        <span class="discount-tips"
          >参与联报活动最高立减￥{{
            localGoodsDetail.discountPrice.toFixed(1)
          }}</span
        >
      </div>
      <div class="discount-contnet">
        <el-tabs type="border-card" class="tabs-box">
          <el-tab-pane
            v-for="(item, index) in unionSubjectList"
            :label="item.subjectName"
            :key="index"
          >
            <div class="subject-card-box">
              <el-card
                shadow="never"
                class="subject-card"
                :class="{ selected: itemInner.isSelected }"
                v-for="(itemInner, indexInner) in item.goodsList"
                :key="indexInner"
                @click.native="innerCardClick(itemInner, index)"
              >
                <div class="subject-name">{{ itemInner.name }}</div>
                <div class="subject-price">
                  ￥
                  <span class="price-num">{{ itemInner.labelPrice }}</span>
                </div>
              </el-card>
            </div>
          </el-tab-pane>
        </el-tabs>
        <!-- 已选科目 -->
        <el-card
          class="selected-card-box"
          v-if="userSelectedSubjectList.length > 1"
        >
          <div slot="header" class="selected-card-head">
            <span>已选科目（{{ userSelectedSubjectList.length }}）</span>
          </div>
          <div class="subject-card-box">
            <el-card
              shadow="never"
              class="subject-card"
              style="margin-right:67px"
              v-for="(item, index) in userSelectedSubjectList"
              :key="index"
            >
              <div class="subject-name">{{ item.name }}</div>
              <div class="subject-price">
                ￥
                <span class="price-num">{{ item.labelPrice }}</span>
              </div>
            </el-card>
            <el-card shadow="never" class="subject-card">
              <div class="old-price">
                <del>原价：￥{{ oldSumPrice }}</del>
              </div>
              <div class="new-price">
                ￥<b>{{ newSumPrice }}</b>
                <div>
                  <el-button size="small" style="width:150px;height:40px" type="primary" @click="goOrderPage"
                    >立即购买</el-button
                  >
                </div>
              </div>
            </el-card>
          </div>
        </el-card>
      </div>
    </el-card>
  </div>
</template>
<script>
// import signIn from "@/views/SignIn.vue";
export default {
  name: "SubjectInfo",
  props: {
    goodsDetail: Object
  },
  data() {
    return {
      msg: "subject-info 课程详情-联报课程信息",
      localGoodsDetail: this.goodsDetail,
      unionSubjectList: [], //可选科目集合 and 指定科目集合
      selectSubjectList: [], //可选科目集合
      isSubjectList: [], //指定科目集合
      ruleList: [], //联报规则集合
      thisSubjectModel: {}, //当前科目
      userSelectedSubjectList: [], //用户选中的课程列表
      //适配类型
      adaptive: ''
    };
  },
  computed: {
    discountSubjectList() {
      // 指定折扣商品列表
      let ref = this;
      let result = [];
      ref.isSubjectList.forEach(ele => {
        result = result.concat(ele.goodsList);
      });
      // console.table(result);
      return result;
    },
    oldSumPrice() {
      let ref = this;
      let result = 0;
      ref.userSelectedSubjectList.forEach(ele => {
        result += ele.labelPrice;
      });
      return result;
    },
    newSumPrice() {
      let ref = this;
      // 全价
      let newPrice = 0;
      ref.userSelectedSubjectList.forEach(ele => {
        newPrice += ele.labelPrice;
      });
      // report最优折扣
      let reportBest = ref.ruleList.filter(ele => {
        return (
          ele.type === "report" &&
          ele.subjectNum <= ref.userSelectedSubjectList.length
        );
      });
      reportBest.sort(function(a, b) {
        return b.price - a.price;
      });
      let reportDiscountNum = reportBest.length ? reportBest[0].price : 0;
      // console.log("当前可用report折扣最大值" + reportDiscountNum);
      console.table(reportBest);
      // subject最优折扣
      let subjectDiscountMap = {};
      ref.ruleList
        .filter(ele => {
          return ele.type === "subject";
        })
        .forEach(ele => {
          subjectDiscountMap[ele.subjectId] = ele.price;
        });
      // 指定科目优惠金额
      let subjectDiscountNum = 0;
      ref.userSelectedSubjectList.forEach(ele => {
        if (subjectDiscountMap[ele.subjectId]) {
          subjectDiscountNum += subjectDiscountMap[ele.subjectId];
        }
      });

      // console.log("当前可用subject折扣总金额", subjectDiscountNum);
      console.table(subjectDiscountMap);
      // 扣除 report 最大折扣额度
      newPrice -= reportDiscountNum;
      // 扣除 subject 最大折扣额度
      newPrice -= subjectDiscountNum;
      return newPrice;
    }
  },
  methods: {
    innerCardClick(itemInner, index) {
      let ref = this;
      let sublingsHasSelected = ref.unionSubjectList[index].goodsList.some(
        ele => {
          return !!ele.isSelected;
        }
      );
      if (sublingsHasSelected && !itemInner.isSelected) {
        return false;
      } else {
        ref.$set(itemInner, "isSelected", !itemInner.isSelected);
        if (itemInner.isSelected) {
          // 追加操作
          ref.userSelectedSubjectList.push(itemInner);
        } else {
          // 找到匹配项，将匹配项从已选列表中删除
          ref.userSelectedSubjectList = ref.userSelectedSubjectList.filter(
            ele => {
              return ele.id !== itemInner.id;
            }
          );
        }
      }
    },
    getCourseGroupInfo() {
      // 获取当前分类的课程列表
      let ref = this;
      let paramData = {
        goodsId: ref.localGoodsDetail.id,
        parentId: ref.localGoodsDetail.parentId
      };
      ref.axios.post("/shop1Api/get/subject/goods/list", paramData).then(res => {
        let data = res.data;
        if (res.data.success) {
          // data
          ref.selectSubjectList = data.entity.selectSubjectList; //可选科目集合
          ref.isSubjectList = data.entity.isSubjectList; //指定科目集合
          ref.ruleList = data.entity.ruleList; //联报规则集合
          ref.thisSubjectModel = data.entity.thisSubjectModel; //当前科目
          // 构建 unionSubjectList
          ref.unionSubjectList = [].concat(
            data.entity.selectSubjectList,
            data.entity.isSubjectList
          );
          // 筛选出当前课程，添加至已选课程列表(thisSubjectModel.goodsList总是会包含当前课程.so,可以直接push)
          ref.userSelectedSubjectList.push(
            ref.thisSubjectModel.goodsList.find(ele => {
              return ele.id === ref.localGoodsDetail.id;
            })
          );
        }
      });
    },
    goOrderPage() {
      // 跳转至创建订单页面
      let ref = this;
      // console.log("ref.userSelectedSubjectList", ref.userSelectedSubjectList);
      let goodsIds = ref.userSelectedSubjectList
        .map(ele => {
          return ele.id;
        })
        .toString();
      this.$router.push({
        path: "/create-order",
        query: {
          goodsIds,
          sellType: ref.localGoodsDetail.sellType,
          type: ref.localGoodsDetail.type,
          goodsList: JSON.stringify(ref.userSelectedSubjectList),
          deliveryTime:
            ref.localGoodsDetail.type === "book"
              ? ref.localGoodsDetail.deliveryTime
              : "",
          newSumPrice: ref.newSumPrice
        }
        // type:  course 课程  book 书籍
      });
    }
  },
  created() {
    this.adaptive = this.$store.state.adaptive
    this.getCourseGroupInfo();
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.subject-info {
  text-align: left;
  .subject-info-content {
    .el-card__header {
      background-color: #fdf6ec;
    }
    .discount-head {
      margin-left: 17px;
      display: flex;
      align-items: center;
      .discount-title {
        font-size: 18px;
        font-family: Hiragino Sans GB;
        font-weight: normal;
        color: #000000;
      }
      .discount-tips {
        margin-left: 22px;
        font-size: 14px;
        font-family: Hiragino Sans GB;
        font-weight: normal;
        color: #ff774d;
      }
    }
    .discount-contnet {
      background-color: #fff;

      .subject-card-box {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        .subject-card {
          width: 253px;
          height: 120px;
          margin-bottom: 20px;
          margin-right: 30px;
          // &:not(:first-child) {
          //   margin-left: 40px;
          // }
          .subject-name {
            font-size: 14px;
            font-family: Hiragino Sans GB;
            font-weight: normal;
            color: #333333;
          }
          .subject-price {
            font-size: 24px;
            padding: 20px;
            font-family: Hiragino Sans GB;
            .price-num {
              font-size: 26px;
            }
          }
          &.selected {
            border-color: lightseagreen;
            position: relative;
            &::after {
              content: "";
              position: absolute;
              display: block;
              width: 0px;
              height: 0px;
              border-right: 20px solid lightseagreen;
              border-bottom: 20px solid lightseagreen;
              border-left: 20px solid transparent;
              border-top: 20px solid transparent;
              right: 0;
              bottom: 0;
            }
          }
        }
      }
      .tabs-box {
        .subject-price {
          color: red;
        }
      }
      .selected-card-box {
        margin-top: 20px;
        // .subject-price {
        //   color: #ccc;
        // }
        .selected-card-head {
          font-size: 22px;
          font-family: Adobe Heiti Std;
          color: #000000;
        }
        .subject-card-box {
          padding: 0 20px;

          .subject-card:not(:first-child) {
            position: relative;
            overflow: visible;
            &::before {
              content: "+";
              position: absolute;
              font-size: 60px;
              left: -54px;
              top: 50%;
              color: #ccc;
              transform: translateY(-50%);
            }
          }
          .subject-card:last-child {
            position: relative;
            overflow: visible;
            &::before {
              content: "=";
              position: absolute;
              font-size: 60px;
              left: -54px;
              top: 50%;
              color: #ccc;
              transform: translateY(-50%);
            }
            .old-price {
              margin-top: -12px;
              color: #ccc;
              font-size: 16px;
              font-family: Hiragino Sans GB;
              font-weight: normal;
              text-decoration: line-through;
              color: #989898;
            }
            .new-price {
              font-size: 24px;
              font-family: Hiragino Sans GB;
              font-weight: normal;
              color: #fb2626;
              b{
                font-size: 36px;
              }
            }
          }
        }
      }
    }
  }
}
.subject-info-model {
  text-align: left;
  .subject-info-content {
    .el-card__header {
      background-color: #fdf6ec;
    }
    .discount-head {
      margin-left: 0.17rem;
      display: flex;
      align-items: center;
      .discount-title {
        font-size: 0.18rem;
        font-family: Hiragino Sans GB;
        font-weight: normal;
        color: #000000;
      }
      .discount-tips {
        margin-left: 0.22rem;
        font-size: 0.14rem;
        font-family: Hiragino Sans GB;
        font-weight: normal;
        color: #ff774d;
      }
    }
    .discount-contnet {
      background-color: #fff;

      .subject-card-box {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        .subject-card {
          width: 2.53rem;
          //height: 1.2rem;
          margin-bottom: 0.2rem;
          margin-right: 0.3rem;
          // &:not(:first-child) {
          //   margin-left: 40px;
          // }
          .subject-name {
            font-size: 0.14rem;
            font-family: Hiragino Sans GB;
            font-weight: normal;
            color: #333333;
          }
          .subject-price {
            font-size: 0.24rem;
            padding: 0.2rem;
            font-family: Hiragino Sans GB;
            .price-num {
              font-size: 0.26rem;
            }
          }
          &.selected {
            border-color: lightseagreen;
            position: relative;
            &::after {
              content: "";
              position: absolute;
              display: block;
              width: 0;
              height: 0;
              border-right: 0.2rem solid lightseagreen;
              border-bottom: 0.2rem solid lightseagreen;
              border-left: 0.2rem solid transparent;
              border-top: 0.2rem solid transparent;
              right: 0;
              bottom: 0;
            }
          }
        }
      }
      .tabs-box {
        .subject-price {
          color: red;
        }
      }
      .selected-card-box {
        margin-top: 0.2rem;
        // .subject-price {
        //   color: #ccc;
        // }
        .selected-card-head {
          font-size: 0.22rem;
          font-family: Adobe Heiti Std;
          color: #000000;
        }
        .subject-card-box {
          padding: 0 0.2rem;

          .subject-card:not(:first-child) {
            position: relative;
            overflow: visible;
            &::before {
              content: "+";
              position: absolute;
              font-size: 0.6rem;
              left: -0.54rem;
              top: 50%;
              color: #ccc;
              transform: translateY(-50%);
            }
          }
          .subject-card:last-child {
            position: relative;
            overflow: visible;
            &::before {
              content: "=";
              position: absolute;
              font-size: 0.6rem;
              left: -0.54rem;
              top: 50%;
              color: #ccc;
              transform: translateY(-50%);
            }
            .old-price {
              margin-top: -0.12rem;
              color: #ccc;
              font-size: 0.16rem;
              font-family: Hiragino Sans GB;
              font-weight: normal;
              text-decoration: line-through;
              color: #989898;
            }
            .new-price {
              font-size: 0.24rem;
              font-family: Hiragino Sans GB;
              font-weight: normal;
              color: #fb2626;
              b{
                font-size: 0.36rem;
              }
            }
          }
        }
      }
    }
  }
}
</style>
<style lang="less">
#subject-info {
  width: 1200px;
  margin: 0 auto;
  .el-card__header {
    background-color: #fdf6ec;
  }
}
#subject-info-model {
  width: 100%;
  margin: 0 auto;
  .el-card__header {
    background-color: #fdf6ec;
  }
}
</style>
