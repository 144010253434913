<template>
  <div class="live-page" id="live-page">
    <div class="tips">
      3秒后将自动为您返回前一页，您也可以通过
      <i class="btn-text" @click="back">点击此处</i>
      立即返回
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import qs from "qs";
export default {
  name: "LivePage",
  data() {
    return {
      msg: "LivePage  直播",
      timer: null
    };
  },
  methods: {
    back() {
      let ref = this;
      clearTimeout(ref.timer);
      ref.$router.go(-1);
    },
    goPolyvWebPage() {
      let ref = this;
      let { channelId, sign, timestamp: ts, userId: userid } = {
        ...ref.$route.query
      };
      // 跳转播放页
      let qsStr = qs.stringify({ sign, ts, userid });
      window.open(
        "https://live.polyv.cn/watch/" + channelId + "?" + qsStr,
        "_blank"
      );
      // 返回前一页
      ref.timer = setTimeout(() => {
        ref.back();
      }, 3000);
    }
  },
  // created() {
  // },
  mounted() {
    let ref = this;
    ref.goPolyvWebPage();
  }
  // components: {}
};
</script>
<style lang="less" scoped>
#live-page {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  .tips {
    .btn-text {
      color: #00cba9;
      text-decoration: underline;
      cursor: pointer;
    }
  }
}
</style>
