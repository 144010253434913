<template>
  <div class="order-center" id="order-center">
    <div :class="adaptive === 'Pc' ? 'order-list' : 'order-list-model'">
      <div class="no-order" v-if="!orderList.length">您暂时还没有订单</div>
      <el-card
        class="order-card"
        v-for="(item, index) in orderList"
        :key="index"
      >
        <div slot="header">
          <div class="order-statusAll">
            <div class="colorRed"></div>
            <span class="order-status">
              {{ orderStatusEnum[item.trxStatus] }}
            </span>
          </div>
        </div>
        <div class="goods-box" :class="{ 'goods-box-unfold': item.isUnfold }">
          <div
            class="order-info"
            v-for="(itemInner, indexInner) in item.detailList"
            :key="indexInner"
          >
            <div class="order-left">
              <img
                class="goods-logo"
                :src="
                  'https://ytk-img.oss-cn-beijing.aliyuncs.com' +
                    itemInner.goodsLogo
                "
                alt
              />
            </div>
            <div class="order-right">
              <p class="goods-name">{{ itemInner.goodsName }}</p>
              <div class="title_details">
                <span>合计 {{ item.detailList.length }} 件商品</span>
                <span style="margin-left:35px;">共计</span>
                <span class="order-amount">￥{{ item.amount }}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="unfold-btn" v-if="item.detailList.length > 1">
          <el-button
            v-if="!item.isUnfold"
            size="small"
            type="text"
            icon="el-icon-arrow-down"
            @click="item.isUnfold = true"
            >展开订单全部商品</el-button
          >
          <el-button
            v-else
            size="small"
            type="text"
            icon="el-icon-arrow-up"
            @click="item.isUnfold = false"
            >收起订单全部商品</el-button
          >
        </div>

        <div class="order-card-foot">
          <span>
            订单号：{{
              item.requestId + "\u3000\u3000\u3000\u3000"
            }}
            下单时间：{{ item.payTime }}</span
          >
          <!-- 未支付订单支持取消订单 -->
<!--          <el-button-->
<!--            v-if="item.trxStatus === 'INIT'"-->
<!--            class="order-btn"-->
<!--            size="small"-->
<!--            type="primary"-->
<!--            @click="goPayPage(item)"-->
<!--            >立即付款</el-button-->
<!--          >-->
          <el-button
              v-if="item.trxStatus === 'INIT'"
              class="order-btn"
              size="small"
              type="primary"
              style="background: #8c939d;border: 0;"
          >请前往医题库App购买</el-button
          >
          <el-button
            v-if="item.trxStatus === 'INIT'"
            class="order-btn"
            style="color:#7E7E7E"
            size="small"
            @click="cancelOrder(item.requestId)"
            >取消订单</el-button
          >
        </div>
      </el-card>
    </div>
    <fouter class="fouter_bot"></fouter>
  </div>
</template>

<script>
// @ is an alias to /src
import fouter from '../../src/components/fouter/fouter'
export default {
  name: "OrderCenter",
  data() {
    return {
      msg: "OrderCenter  订单中心",
      orderList: [],
      page: {
        currentPage: 1,
        pageSize: 10,
        total: 0
      },
      orderStatusEnum: {
        INIT: "未支付",
        SUCCESS: "已支付",
        REFUND: "退款",
        CANCEL: "已取消"
      },
      //适配类型
      adaptive: ''
    };
  },
  methods: {
    goPayPage(orderData) {
      let ref = this;
      ref.$router.push({
        path: "/pay-order",
        query: {
          amount: orderData.amount,
          requestId: orderData.requestId
        }
      });
    },
    cancelOrder(requestIds) {
      // 取消订单
      let ref = this;
      let paramData = {
        requestIds,
        userId : ref.$store.state.userInfo.userId,
      };
      ref.axios.post("/shop1Api/user/order", paramData).then(res => {
        let data = res.data;
        if (data.success) {
          ref.$message({
            type: "success",
            message: "订单已取消"
          });
        } else {
          // ref.$message({
          //   message: "订单取消失败，请联系管理员",
          //   type: "error"
          // });
        }
      });
    },
    getOrderList() {
      // 获取当前分类的课程列表
      let ref = this;
      let paramData = {
        "page.currentPage": ref.page.currentPage,
        "page.pageSize": ref.page.pageSize,
        userId : ref.$store.state.userInfo.userId,
        isSend: false //是否只查询有快递的订单
      };
      ref.axios.post("/shop1Api/user/order", paramData).then(res => {
        let data = res.data;
        if (data.success) {
          data.entity.trxOrderList.forEach(ele => {
            ele.isUnfold = false;
          });
          ref.orderList = data.entity.trxOrderList;
          // ref.orderList = [
          //   {
          //     couponInfo: "联报优惠减￥650.00",
          //     isUnfold: false,
          //     detailList: [
          //       {
          //         goodsId: 106,
          //         goodsPrice: 999,
          //         goodsLogo: "/20200520/goods/SkHS34FKErc5iQfFs5b7.png",
          //         goodsName: "测试"
          //       }
          //     ],
          //     type: "book",
          //     requestId: "3000000014975392",
          //     couponAmount: 650,
          //     payType: "后台审核",
          //     trxStatus: "SUCCESS",
          //     amount: 3901,
          //     orderAmount: 4551,
          //     createTime: "2020.05.26 10:19:32",
          //     sellType: 1,
          //     payTime: "2020.05.26 11:09:16"
          //   },
          //   {
          //     couponInfo: "联报优惠减￥650.00",
          //     isUnfold: false,
          //     detailList: [
          //       {
          //         goodsId: 106,
          //         goodsPrice: 999,
          //         goodsLogo: "/20200520/goods/SkHS34FKErc5iQfFs5b7.png",
          //         goodsName: "测试"
          //       },
          //       {
          //         goodsId: 107,
          //         goodsPrice: 999,
          //         goodsLogo: "/20200522/goods/iRnQhbYehZKPGCre7WcY.jpg",
          //         goodsName: "考研英语基础课"
          //       },
          //       {
          //         goodsId: 108,
          //         goodsPrice: 888,
          //         goodsLogo: "/20200522/goods/EQyZdbRtCHGpPXC4MP4J.jpg",
          //         goodsName: "生物化学基础课"
          //       },
          //       {
          //         goodsId: 109,
          //         goodsPrice: 333,
          //         goodsLogo: "/20200522/goods/6NzpyacPGXs5pZm8mzsW.png",
          //         goodsName: "20考研最新复试课"
          //       },
          //       {
          //         goodsId: 111,
          //         goodsPrice: 999,
          //         goodsLogo: "/20200522/goods/nj5XyiiQcjMrfDBQ8hGd.png",
          //         goodsName: "19考研复试课"
          //       },
          //       {
          //         goodsId: 114,
          //         goodsPrice: 333,
          //         goodsLogo: "/20200522/goods/d2dA2SYY7R4AsGHHPXbT.jpg",
          //         goodsName: "21中医考研"
          //       }
          //     ],
          //     type: "book",
          //     requestId: "3000000014975392",
          //     couponAmount: 650,
          //     payType: "后台审核",
          //     trxStatus: "SUCCESS",
          //     amount: 3901,
          //     orderAmount: 4551,
          //     createTime: "2020.05.26 10:19:32",
          //     sellType: 1,
          //     payTime: "2020.05.26 11:09:16"
          //   },
          //   {
          //     couponInfo: "联报优惠减￥650.00",
          //     isUnfold: false,
          //     detailList: [
          //       {
          //         goodsId: 106,
          //         goodsPrice: 999,
          //         goodsLogo: "/20200520/goods/SkHS34FKErc5iQfFs5b7.png",
          //         goodsName: "测试"
          //       },
          //       {
          //         goodsId: 107,
          //         goodsPrice: 999,
          //         goodsLogo: "/20200522/goods/iRnQhbYehZKPGCre7WcY.jpg",
          //         goodsName: "考研英语基础课"
          //       },
          //       {
          //         goodsId: 108,
          //         goodsPrice: 888,
          //         goodsLogo: "/20200522/goods/EQyZdbRtCHGpPXC4MP4J.jpg",
          //         goodsName: "生物化学基础课"
          //       },
          //       {
          //         goodsId: 109,
          //         goodsPrice: 333,
          //         goodsLogo: "/20200522/goods/6NzpyacPGXs5pZm8mzsW.png",
          //         goodsName: "20考研最新复试课"
          //       },
          //       {
          //         goodsId: 111,
          //         goodsPrice: 999,
          //         goodsLogo: "/20200522/goods/nj5XyiiQcjMrfDBQ8hGd.png",
          //         goodsName: "19考研复试课"
          //       },
          //       {
          //         goodsId: 114,
          //         goodsPrice: 333,
          //         goodsLogo: "/20200522/goods/d2dA2SYY7R4AsGHHPXbT.jpg",
          //         goodsName: "21中医考研"
          //       }
          //     ],
          //     type: "book",
          //     requestId: "3000000014975392",
          //     couponAmount: 650,
          //     payType: "后台审核",
          //     trxStatus: "SUCCESS",
          //     amount: 3901,
          //     orderAmount: 4551,
          //     createTime: "2020.05.26 10:19:32",
          //     sellType: 1,
          //     payTime: "2020.05.26 11:09:16"
          //   },
          //   {
          //     couponInfo: "联报优惠减￥650.00",
          //     isUnfold: false,
          //     detailList: [
          //       {
          //         goodsId: 106,
          //         goodsPrice: 999,
          //         goodsLogo: "/20200520/goods/SkHS34FKErc5iQfFs5b7.png",
          //         goodsName: "测试"
          //       },
          //       {
          //         goodsId: 107,
          //         goodsPrice: 999,
          //         goodsLogo: "/20200522/goods/iRnQhbYehZKPGCre7WcY.jpg",
          //         goodsName: "考研英语基础课"
          //       },
          //       {
          //         goodsId: 108,
          //         goodsPrice: 888,
          //         goodsLogo: "/20200522/goods/EQyZdbRtCHGpPXC4MP4J.jpg",
          //         goodsName: "生物化学基础课"
          //       },
          //       {
          //         goodsId: 109,
          //         goodsPrice: 333,
          //         goodsLogo: "/20200522/goods/6NzpyacPGXs5pZm8mzsW.png",
          //         goodsName: "20考研最新复试课"
          //       },
          //       {
          //         goodsId: 111,
          //         goodsPrice: 999,
          //         goodsLogo: "/20200522/goods/nj5XyiiQcjMrfDBQ8hGd.png",
          //         goodsName: "19考研复试课"
          //       },
          //       {
          //         goodsId: 114,
          //         goodsPrice: 333,
          //         goodsLogo: "/20200522/goods/d2dA2SYY7R4AsGHHPXbT.jpg",
          //         goodsName: "21中医考研"
          //       }
          //     ],
          //     type: "book",
          //     requestId: "3000000014975392",
          //     couponAmount: 650,
          //     payType: "后台审核",
          //     trxStatus: "SUCCESS",
          //     amount: 3901,
          //     orderAmount: 4551,
          //     createTime: "2020.05.26 10:19:32",
          //     sellType: 1,
          //     payTime: "2020.05.26 11:09:16"
          //   },
          //   {
          //     couponInfo: "联报优惠减￥650.00",
          //     isUnfold: false,
          //     detailList: [
          //       {
          //         goodsId: 106,
          //         goodsPrice: 999,
          //         goodsLogo: "/20200520/goods/SkHS34FKErc5iQfFs5b7.png",
          //         goodsName: "测试"
          //       },
          //       {
          //         goodsId: 107,
          //         goodsPrice: 999,
          //         goodsLogo: "/20200522/goods/iRnQhbYehZKPGCre7WcY.jpg",
          //         goodsName: "考研英语基础课"
          //       },
          //       {
          //         goodsId: 108,
          //         goodsPrice: 888,
          //         goodsLogo: "/20200522/goods/EQyZdbRtCHGpPXC4MP4J.jpg",
          //         goodsName: "生物化学基础课"
          //       },
          //       {
          //         goodsId: 109,
          //         goodsPrice: 333,
          //         goodsLogo: "/20200522/goods/6NzpyacPGXs5pZm8mzsW.png",
          //         goodsName: "20考研最新复试课"
          //       },
          //       {
          //         goodsId: 111,
          //         goodsPrice: 999,
          //         goodsLogo: "/20200522/goods/nj5XyiiQcjMrfDBQ8hGd.png",
          //         goodsName: "19考研复试课"
          //       },
          //       {
          //         goodsId: 114,
          //         goodsPrice: 333,
          //         goodsLogo: "/20200522/goods/d2dA2SYY7R4AsGHHPXbT.jpg",
          //         goodsName: "21中医考研"
          //       }
          //     ],
          //     type: "book",
          //     requestId: "3000000014975392",
          //     couponAmount: 650,
          //     payType: "后台审核",
          //     trxStatus: "SUCCESS",
          //     amount: 3901,
          //     orderAmount: 4551,
          //     createTime: "2020.05.26 10:19:32",
          //     sellType: 1,
          //     payTime: "2020.05.26 11:09:16"
          //   },
          //   {
          //     couponInfo: "联报优惠减￥650.00",
          //     isUnfold: false,
          //     detailList: [
          //       {
          //         goodsId: 106,
          //         goodsPrice: 999,
          //         goodsLogo: "/20200520/goods/SkHS34FKErc5iQfFs5b7.png",
          //         goodsName: "测试"
          //       },
          //       {
          //         goodsId: 107,
          //         goodsPrice: 999,
          //         goodsLogo: "/20200522/goods/iRnQhbYehZKPGCre7WcY.jpg",
          //         goodsName: "考研英语基础课"
          //       },
          //       {
          //         goodsId: 108,
          //         goodsPrice: 888,
          //         goodsLogo: "/20200522/goods/EQyZdbRtCHGpPXC4MP4J.jpg",
          //         goodsName: "生物化学基础课"
          //       },
          //       {
          //         goodsId: 109,
          //         goodsPrice: 333,
          //         goodsLogo: "/20200522/goods/6NzpyacPGXs5pZm8mzsW.png",
          //         goodsName: "20考研最新复试课"
          //       },
          //       {
          //         goodsId: 111,
          //         goodsPrice: 999,
          //         goodsLogo: "/20200522/goods/nj5XyiiQcjMrfDBQ8hGd.png",
          //         goodsName: "19考研复试课"
          //       },
          //       {
          //         goodsId: 114,
          //         goodsPrice: 333,
          //         goodsLogo: "/20200522/goods/d2dA2SYY7R4AsGHHPXbT.jpg",
          //         goodsName: "21中医考研"
          //       }
          //     ],
          //     type: "book",
          //     requestId: "3000000014975392",
          //     couponAmount: 650,
          //     payType: "后台审核",
          //     trxStatus: "SUCCESS",
          //     amount: 3901,
          //     orderAmount: 4551,
          //     createTime: "2020.05.26 10:19:32",
          //     sellType: 1,
          //     payTime: "2020.05.26 11:09:16"
          //   },
          //   {
          //     amount: 4201,
          //     isUnfold: false,
          //     detailList: [
          //       {
          //         goodsId: 107,
          //         goodsPrice: 999,
          //         goodsLogo: "/20200522/goods/iRnQhbYehZKPGCre7WcY.jpg",
          //         goodsName: "考研英语基础课"
          //       },
          //       {
          //         goodsId: 108,
          //         goodsPrice: 888,
          //         goodsLogo: "/20200522/goods/EQyZdbRtCHGpPXC4MP4J.jpg",
          //         goodsName: "生物化学基础课"
          //       },
          //       {
          //         goodsId: 114,
          //         goodsPrice: 333,
          //         goodsLogo: "/20200522/goods/d2dA2SYY7R4AsGHHPXbT.jpg",
          //         goodsName: "21中医考研"
          //       },
          //       {
          //         goodsId: 106,
          //         goodsPrice: 999,
          //         goodsLogo: "/20200520/goods/SkHS34FKErc5iQfFs5b7.png",
          //         goodsName: "测试"
          //       },
          //       {
          //         goodsId: 109,
          //         goodsPrice: 333,
          //         goodsLogo: "/20200522/goods/6NzpyacPGXs5pZm8mzsW.png",
          //         goodsName: "20考研最新复试课"
          //       },
          //       {
          //         goodsId: 111,
          //         goodsPrice: 999,
          //         goodsLogo: "/20200522/goods/nj5XyiiQcjMrfDBQ8hGd.png",
          //         goodsName: "19考研复试课"
          //       }
          //     ],
          //     type: "book",
          //     orderAmount: 4551,
          //     requestId: "3000001989499070",
          //     createTime: "2020.05.26 09:31:56",
          //     sellType: 0,
          //     trxStatus: "INIT"
          //   },
          //   {
          //     amount: 4201,
          //     isUnfold: false,
          //     detailList: [
          //       {
          //         goodsId: 107,
          //         goodsPrice: 999,
          //         goodsLogo: "/20200522/goods/iRnQhbYehZKPGCre7WcY.jpg",
          //         goodsName: "考研英语基础课"
          //       },
          //       {
          //         goodsId: 108,
          //         goodsPrice: 888,
          //         goodsLogo: "/20200522/goods/EQyZdbRtCHGpPXC4MP4J.jpg",
          //         goodsName: "生物化学基础课"
          //       },
          //       {
          //         goodsId: 114,
          //         goodsPrice: 333,
          //         goodsLogo: "/20200522/goods/d2dA2SYY7R4AsGHHPXbT.jpg",
          //         goodsName: "21中医考研"
          //       },
          //       {
          //         goodsId: 106,
          //         goodsPrice: 999,
          //         goodsLogo: "/20200520/goods/SkHS34FKErc5iQfFs5b7.png",
          //         goodsName: "测试"
          //       },
          //       {
          //         goodsId: 109,
          //         goodsPrice: 333,
          //         goodsLogo: "/20200522/goods/6NzpyacPGXs5pZm8mzsW.png",
          //         goodsName: "20考研最新复试课"
          //       },
          //       {
          //         goodsId: 111,
          //         goodsPrice: 999,
          //         goodsLogo: "/20200522/goods/nj5XyiiQcjMrfDBQ8hGd.png",
          //         goodsName: "19考研复试课"
          //       }
          //     ],
          //     type: "book",
          //     orderAmount: 4551,
          //     requestId: "3000001989499070",
          //     createTime: "2020.05.26 09:31:56",
          //     sellType: 0,
          //     trxStatus: "INIT"
          //   },
          //   {
          //     amount: 4201,
          //     isUnfold: false,
          //     detailList: [
          //       {
          //         goodsId: 107,
          //         goodsPrice: 999,
          //         goodsLogo: "/20200522/goods/iRnQhbYehZKPGCre7WcY.jpg",
          //         goodsName: "考研英语基础课"
          //       },
          //       {
          //         goodsId: 108,
          //         goodsPrice: 888,
          //         goodsLogo: "/20200522/goods/EQyZdbRtCHGpPXC4MP4J.jpg",
          //         goodsName: "生物化学基础课"
          //       },
          //       {
          //         goodsId: 114,
          //         goodsPrice: 333,
          //         goodsLogo: "/20200522/goods/d2dA2SYY7R4AsGHHPXbT.jpg",
          //         goodsName: "21中医考研"
          //       },
          //       {
          //         goodsId: 106,
          //         goodsPrice: 999,
          //         goodsLogo: "/20200520/goods/SkHS34FKErc5iQfFs5b7.png",
          //         goodsName: "测试"
          //       },
          //       {
          //         goodsId: 109,
          //         goodsPrice: 333,
          //         goodsLogo: "/20200522/goods/6NzpyacPGXs5pZm8mzsW.png",
          //         goodsName: "20考研最新复试课"
          //       },
          //       {
          //         goodsId: 111,
          //         goodsPrice: 999,
          //         goodsLogo: "/20200522/goods/nj5XyiiQcjMrfDBQ8hGd.png",
          //         goodsName: "19考研复试课"
          //       }
          //     ],
          //     type: "book",
          //     orderAmount: 4551,
          //     requestId: "3000001989499070",
          //     createTime: "2020.05.26 09:31:56",
          //     sellType: 0,
          //     trxStatus: "INIT"
          //   },
          //   {
          //     amount: 4201,
          //     isUnfold: false,
          //     detailList: [
          //       {
          //         goodsId: 107,
          //         goodsPrice: 999,
          //         goodsLogo: "/20200522/goods/iRnQhbYehZKPGCre7WcY.jpg",
          //         goodsName: "考研英语基础课"
          //       },
          //       {
          //         goodsId: 108,
          //         goodsPrice: 888,
          //         goodsLogo: "/20200522/goods/EQyZdbRtCHGpPXC4MP4J.jpg",
          //         goodsName: "生物化学基础课"
          //       },
          //       {
          //         goodsId: 114,
          //         goodsPrice: 333,
          //         goodsLogo: "/20200522/goods/d2dA2SYY7R4AsGHHPXbT.jpg",
          //         goodsName: "21中医考研"
          //       },
          //       {
          //         goodsId: 106,
          //         goodsPrice: 999,
          //         goodsLogo: "/20200520/goods/SkHS34FKErc5iQfFs5b7.png",
          //         goodsName: "测试"
          //       },
          //       {
          //         goodsId: 109,
          //         goodsPrice: 333,
          //         goodsLogo: "/20200522/goods/6NzpyacPGXs5pZm8mzsW.png",
          //         goodsName: "20考研最新复试课"
          //       },
          //       {
          //         goodsId: 111,
          //         goodsPrice: 999,
          //         goodsLogo: "/20200522/goods/nj5XyiiQcjMrfDBQ8hGd.png",
          //         goodsName: "19考研复试课"
          //       }
          //     ],
          //     type: "book",
          //     orderAmount: 4551,
          //     requestId: "3000001989499070",
          //     createTime: "2020.05.26 09:31:56",
          //     sellType: 0,
          //     trxStatus: "INIT"
          //   }
          // ];
        }
      });
    }
  },
  created() {
    this.adaptive = this.$store.state.adaptive
    this.getOrderList();
  },
  components:{
    fouter
  }
};
</script>
<style lang="less" scoped>
.order-center {
  p {
    margin: 0;
    padding: 0;
  }
  text-align: left;
  .order-list {
    min-height: calc(100vh - 160px);
    width: 1200px;
    margin: 0 auto;
    padding-top: 70px;
    .no-order {
      padding-top: 200px;
      text-align: center;
    }
    .order-card {
      margin-bottom: 20px;
      .order-statusAll {
        display: flex;
        flex-direction: row;
        .colorRed {
          width: 6px;
          height: 20px;
          background: #2065ff;
          margin-right: 13px;
        }
        .order-status {
          font-size: 18px;
          font-weight: bold;
          display: flex;
          flex-direction: row;
        }
      }
      .goods-box {
        height: 160px;
        max-height: 160px;
        overflow: hidden;
        transition: all 1s;
        &.goods-box-unfold {
          height: auto;
          max-height: 5000px;
        }
        .order-info {
          border-radius: 6px;
          overflow: hidden;
          display: flex;
          margin-bottom: 20px;
          .order-left {
            flex: 0 0 230px;
            .goods-logo {
              display: block;
              width: 233px;
              height: 150px;
            }
          }
          .order-right {
            flex: auto;
            padding: 3px 0 0 29px;
            position: relative;
            .goods-name {
              font-size: 22px;
              font-family: Adobe Heiti Std;
              font-weight: normal;
              color: #000000;
              height: 70%;
            }
            .title_details {
              height: 30%;
              display: flex;
              align-items: flex-end;
              span {
                font-size: 18px;
                font-family: Hiragino Sans GB;
                font-weight: normal;
                color: #000000;
              }
              .order-amount {
                font-size: 24px;
                font-family: Hiragino Sans GB;
                font-weight: normal;
                color: #fb2626;
              }
            }
          }
        }
      }
      .unfold-btn {
        text-align: center;
      }
      .order-card-foot {
        padding-top: 20px;
        margin-top: 30px;
        border-top: 1px solid #ccc;
        span {
          font-size: 14px;
          font-family: Hiragino Sans GB;
          font-weight: normal;
          color: #000000;
        }
        .order-amount {
          font-size: 24px;
          color: red;
        }
        .order-btn {
          float: right;
          vertical-align: text-bottom;
          margin-left: 21px;
        }
      }
    }
  }
  .order-list-model {
    min-height: calc(100vh - 160px);
    width: 100%;
    margin: 0 auto;
    padding-top: 0.7rem;
    .no-order {
      padding-top: 2rem;
      text-align: center;
    }
    .order-card {
      margin-bottom: 0.2rem;
      .order-statusAll {
        display: flex;
        flex-direction: row;
        .colorRed {
          width: 0.06rem;
          height: 0.2rem;
          background: #2065ff;
          margin-right: 0.13rem;
        }
        .order-status {
          font-size: 0.18rem;
          font-weight: bold;
          display: flex;
          flex-direction: row;
        }
      }
      .goods-box {
        height: 1.6rem;
        max-height: 1.6rem;
        overflow: hidden;
        transition: all 1s;
        &.goods-box-unfold {
          height: auto;
          max-height: 50rem;
        }
        .order-info {
          border-radius: 0.06rem;
          overflow: hidden;
          display: flex;
          margin-bottom: 0.2rem;
          .order-left {
            flex: 0 0 2.3rem;
            .goods-logo {
              display: block;
              width: 2.33rem;
              height: 1.5rem;
            }
          }
          .order-right {
            flex: auto;
            padding: 0.03rem 0 0 0.29rem;
            position: relative;
            .goods-name {
              font-size: 0.22rem;
              font-family: Adobe Heiti Std;
              font-weight: normal;
              color: #000000;
              height: 70%;
            }
            .title_details {
              height: 30%;
              display: flex;
              align-items: flex-end;
              span {
                font-size: 0.18rem;
                font-family: Hiragino Sans GB;
                font-weight: normal;
                color: #000000;
              }
              .order-amount {
                font-size: 0.24rem;
                font-family: Hiragino Sans GB;
                font-weight: normal;
                color: #fb2626;
              }
            }
          }
        }
      }
      .unfold-btn {
        text-align: center;
      }
      .order-card-foot {
        padding-top: 0.2rem;
        margin-top: 0.3rem;
        border-top: 1px solid #ccc;
        span {
          font-size: 0.14rem;
          font-family: Hiragino Sans GB;
          font-weight: normal;
          color: #000000;
        }
        .order-amount {
          font-size: 0.24rem;
          color: red;
        }
        .order-btn {
          margin: 0.2rem 0;
          float: right;
          vertical-align: text-bottom;
          margin-left: 0.21rem;
        }
      }
    }
  }
  .fouter_bot{
    width: 100%;
    height: 160px;
  }
}
</style>
