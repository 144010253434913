<template>
  <div :class=" adaptive === 'Pc' ? 'course-info' : 'course-info-model'">
    <div class="course-info-content">
      <div class="course-info-left hidden-sm-and-down">
        <img
          v-if="localGoodsDetail.logoType === 'img'"
          :src="
            'https://ytk-img.oss-cn-beijing.aliyuncs.com' +
              localGoodsDetail.logo
          "
          alt
          class="course-img"
        />
        <video
          v-else-if="localGoodsDetail.logoType === 'video'"
          class="course-img"
          controls
        >
          <source
            :src="
              'https://ytk-img.oss-cn-beijing.aliyuncs.com' +
                localGoodsDetail.logo
            "
            type="video/mp4"
          />
          您的浏览器不支持 video 标签。
        </video>
      </div>
      <div class="course-info-right">
        <p class="course-title">{{ localGoodsDetail.name }}</p>
        <p class="course-label">
          {{ localGoodsDetail.labels + "\u3000\u3000" }} 课时:{{
            localGoodsDetail.classHour
          }}
          <!-- <template v-if="localGoodsDetail.type === 'course'">
            {{ localGoodsDetail.labels + "\u3000\u3000" }} 课时:{{
              localGoodsDetail.classHour
            }}
          </template>
          <template v-else>
            {{ localGoodsDetail.labels + "\u3000\u3000" }}
          </template>-->
        </p>
        <div class="course-btns-box" v-if="!!localGoodsDetail.aliasList">
          <el-button
            :key="index"
            :type="item.isThis ? 'primary' : ''"
            @click="aliasClick(item)"
            round
            size="small"
            v-for="(item, index) in localGoodsDetail.aliasList"
            >{{ item.name }}</el-button
          >
        </div>
        <div class="course-price-box">
          <div
            class="course-price"
            :class="{ 'is-free': !localGoodsDetail.lablePrice }"
          >
            <span v-if="!!localGoodsDetail.limitTime" class="price-original">{{
              `￥${localGoodsDetail.price}`
            }}</span>
            <span class="price-discount">{{ getPrice(localGoodsDetail) }}</span>
            <span class="course-prise-avg">
             {{
                localGoodsDetail.classHour === 0 ? '' : "课时单价仅￥" +
                (
                  localGoodsDetail.labelPrice / localGoodsDetail.classHour
                ).toFixed(2)
              }}
            </span>
          </div>
          <!-- </template> -->
        </div>
        <div class="discunt-box" v-if="!!localGoodsDetail.limitTime">
          <span>距优惠结束还有</span>
          <span>{{ durationTime(localGoodsDetail.limitTime) }}</span>
        </div>
        <div class="course-buy-box">
          <el-button
            @click="infoBtnHandler"
            style="width:150px;height:40px;"
            type="primary"
            v-if="localGoodsDetail.isBuy"
            >{{ getBtnText() }}</el-button
          >
          <el-button
              style="width:180px;height:40px;background: #8c939d !important;border: 0;"
              type="primary"
              v-if="!localGoodsDetail.isBuy"
          >请前往医题库App购买</el-button
          >
          <span class="course-sell">已售{{ localGoodsDetail.studyNum }}</span>
          <span class="course-sell"
            >限购{{ localGoodsDetail.limitSellNum }}</span
          >
        </div>
      </div>
    </div>
  </div>
</template>
<script>
var moment = require("moment");
// import signIn from "@/views/SignIn.vue";
export default {
  name: "CourseInfo",
  props: {
    goodsDetail: Object
  },
  data() {
    return {
      msg: "course-info 课程详情-课程信息",
      localGoodsDetail: this.goodsDetail,
      discoountTimer: null,
      //适配类型
      adaptive : ''
    };
  },
  computed: {},
  methods: {
    durationTime(timestamp) {
      let result = "";
      if (timestamp) {
        result =
          moment.duration(timestamp).months() +
          "月" +
          moment.duration(timestamp).days() +
          "天" +
          moment.duration(timestamp).hours() +
          "小时" +
          moment.duration(timestamp).minutes() +
          "分钟" +
          moment.duration(timestamp).seconds() +
          "秒";
      }
      return result;
    },
    initTimer() {
      let ref = this;
      ref.discoountTimer = setInterval(function() {
        console.log("loop");
        if (!ref.localGoodsDetail.limitTime) {
          clearInterval(ref.discoountTimer);
          return false;
        } else {
          ref.localGoodsDetail.limitTime -= 1000;
        }
      }, 1000);
    },
    getPrice(item) {
      let priceText = "";
      if (item.labelPrice) {
        priceText = `￥${item.labelPrice}`;
      } else {
        priceText = "免费";
      }
      return priceText;
    },
    getBtnText() {
      let ref = this;
      let btnText = "";
      // type:  course 课程  book 书籍
      btnText = ref.localGoodsDetail.isBuy ? "进入教室" : "立即抢购";
      // if (ref.localGoodsDetail.type === "book") {
      //   btnText = ref.localGoodsDetail.isBuy ? "进入教室" : "立即抢购";
      // } else {
      //   btnText = ref.localGoodsDetail.isBuy ? "进入教室" : "立即抢购";
      // }
      return btnText;
    },
    infoBtnHandler() {
      let ref = this;
      if (ref.localGoodsDetail.isBuy) {
        // 去往课程详情
        ref.$router.push({
          path: "/user-course-detail",
          query: { courseId: ref.localGoodsDetail.courseId }
        });
      } else {
        ref.goOrderPage();
      }
    },
    goOrderPage() {
      let ref = this;
      let {
        id,
        name,
        type, //book/course
        sellType, //1联报，2单品
        logo,
        labelPrice,
        courseId
      } = ref.localGoodsDetail;
      let goodsInfo = {
        id,
        name,
        type, //book/course
        sellType, //1联报，2单品
        logo,
        labelPrice,
        courseId
      };
      ref.$router.push({
        path: "/create-order",
        query: {
          goodsIds: ref.localGoodsDetail.id,
          sellType: 2,
          // type: ref.localGoodsDetail.type,
          type: ref.localGoodsDetail.postType,
          goodsList: JSON.stringify([goodsInfo]),
          deliveryTime:
            ref.localGoodsDetail.type === "book"
              ? ref.localGoodsDetail.deliveryTime
              : ""
        }
        // type:  course 课程  book 书籍
      });
    },
    aliasClick(item) {
      let ref = this;
      // 切换课程详情页
      if (item.id != ref.$route.query.id) {
        ref.$router.push({
          path: "/course-detail-single",
          query: { id: item.id }
        });
      }
    }
  },
  created() {
    this.adaptive = this.$store.state.adaptive
    let ref = this;
    // ref.localGoodsDetail.limitTime = 10000;
    // 启动优惠时间倒计时
    ref.initTimer();
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.course-info {
  width: 1200px;
  margin: 0 auto;
  p {
    margin: 0;
    padding: 0;
  }
  .course-info-content {
    display: flex;
    .course-info-left {
      width: 460px;
      height: 297px;
      flex: 0 0 460px;
      .course-img {
        display: block;
        width: 100%;
        height: 100%;
        // border: 1px solid #ccc;
      }
    }
    .course-info-right {
      flex: auto;
      padding-left: 20px;
      text-align: left;
      .course-title {
        border-bottom: 1px solid #ccc;
        font-size: 24px;
        font-family: Adobe Heiti Std;
        font-weight: normal;
        color: #000000;
        line-height: 42px;
        padding-bottom: 23px;
      }
      .course-label {
        padding-top: 15px;
        color: #ccc;
        font-size: 16px;
        font-family: Hiragino Sans GB;
        font-weight: normal;
        color: #989898;
        line-height: 42px;
      }
      .course-btns-box {
        padding: 10px;
      }
      .course-price-box {
        padding: 10px;
        .course-price {
          .price-original {
            font-size: 16px;
            color: grey;
            text-decoration: line-through;
          }
          .price-discount {
            font-size: 24px;
            font-family: Hiragino Sans GB;
            font-weight: normal;
            color: #fb2626;
            line-height: 42px;
            &.is-free {
              font-size: 16px;
            }
          }
          .course-prise-avg {
            margin-left: 22px;
            font-size: 16px;
            font-family: Hiragino Sans GB;
            font-weight: normal;
            color: #000000;
            line-height: 42px;
          }
        }
      }
      .discunt-box {
        line-height: 30px;
        color: red;
        text-align: left;
      }
      .course-buy-box {
        padding: 10px;
        .course-sell {
          color: #ccc;
          margin-left: 40px;
          font-size: 16px;
          font-family: Hiragino Sans GB;
          font-weight: normal;
          color: #a0a0a0;
          line-height: 42px;
        }
      }
    }
  }
}
.course-info-model {
  width: 100%;
  margin: 0 auto;
  p {
    margin: 0;
    padding: 0;
  }
  .course-info-content {
    display: flex;
    .course-info-left {
      width: 4.6rem;
      height: 2.97rem;
      flex: 0 0 4.6rem;
      .course-img {
        display: block;
        width: 100%;
        height: 100%;
        // border: 1px solid #ccc;
      }
    }
    .course-info-right {
      flex: auto;
      padding-left: 0.2rem;
      text-align: left;
      .course-title {
        border-bottom: 1px solid #ccc;
        font-size: 0.24rem;
        font-family: Adobe Heiti Std;
        font-weight: normal;
        color: #000000;
        line-height: 0.42rem;
        padding-bottom: 0.23rem;
      }
      .course-label {
        padding-top: 0.15rem;
        color: #ccc;
        font-size: 0.16rem;
        font-family: Hiragino Sans GB;
        font-weight: normal;
        color: #989898;
        line-height: 0.42rem;
      }
      .course-btns-box {
        padding: 0.1rem;
      }
      .course-price-box {
        padding: 0.1rem;
        .course-price {
          .price-original {
            font-size: 0.16rem;
            color: grey;
            text-decoration: line-through;
          }
          .price-discount {
            font-size: 0.24rem;
            font-family: Hiragino Sans GB;
            font-weight: normal;
            color: #fb2626;
            line-height: 0.42rem;
            &.is-free {
              font-size: 0.16rem;
            }
          }
          .course-prise-avg {
            margin-left: 0.22rem;
            font-size: 0.16rem;
            font-family: Hiragino Sans GB;
            font-weight: normal;
            color: #000000;
            line-height: 0.42rem;
          }
        }
      }
      .discunt-box {
        line-height: 0.3rem;
        color: red;
        text-align: left;
      }
      .course-buy-box {
        padding: 0.1rem;
        .course-sell {
          color: #ccc;
          margin-left: 0.4rem;
          font-size: 0.16rem;
          font-family: Hiragino Sans GB;
          font-weight: normal;
          color: #a0a0a0;
          line-height: 0.42rem;
        }
      }
    }
  }
}
</style>
<style>
.el-button--primary{
  background: #3399FF !important;
  border-color: #3399FF !important;
}
.el-button--small:hover{
  /*background: #3399FF;*/
  /*border-color: #3399FF;;*/
}
</style>
