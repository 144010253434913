<template>
  <div class="box">
    <div class="foot-box" :style="adaptive === 'model' ? 'padding:0 0.3rem' : ''">
      <p :class="adaptive === 'Pc' ? 'foot-line line-1' : 'foot-line-model line-1'">
        <span>全国统一咨询热线：400-6600-397（免费咨询）</span>
        <span :style="adaptive === 'Pc' ? 'margin-left:70px;' : 'display:block;'"
        >全国24小时咨询专线：13466663121</span
        >
      </p>
      <div
          :class="adaptive === 'Pc' ? 'foot-line' : 'foot-line-model'"
          style="margin-top:45px;display:block;padding:0;"
      >
        咨询报名及面授地址：北京市昌平区沙阳路18号北京科技职业学院
      </div>
      <p :class="adaptive === 'Pc' ? 'foot-line line-2' : 'foot-line-model line-2'">
            <span style="margin-left:10px;">
              Copyright &copy; {{ new Date().getFullYear() }} www.medkaoyan.com ALL
              Rights Reserved 版权所有:北京世纪研大教育科技有限公司
              京ICP备11014018号-13
            </span>
      </p>
    </div>
  </div>
</template>

<script>
export default {
  data(){
    return{
      //适配类型
      adaptive : ''
    }
  },
  created() {
    this.adaptive = this.$store.state.adaptive
  }
}
</script>

<style scoped lang="less">
.box {
  padding-top: 20px;
  width: 100%;
  text-align: center;
}

.foot-box {
  background: #333638;

  p {
    margin: 0;
    padding: 0;
  }

  .foot-line {
    padding-top: 38px;
    font-size: 14px;
    font-family: Hiragino Sans GB;
    font-weight: normal;
    color: #a0a0a0;
  }
  .foot-line-model {
    padding-top: 0.38rem;
    font-size: 0.14rem;
    font-family: Hiragino Sans GB;
    font-weight: normal;
    color: #a0a0a0;
  }

  .line-2 {
    margin-top: 20px !important;
    padding: 0 0 24px 0;
  }
}
</style>