<template>
  <div :class="adaptive === 'Pc' ? 'course-detail-boundle' : 'course-detail-boundle-model'" id="course-detail-boundle">
    <div class="boundle-list">
      <CourseCard
        v-for="(item, index) in goodsList"
        :courseData="item"
        :key="index"
        @click.native="goCourseDetail(item)"
      />
      <!-- 追加元素，用于对齐 -->
      <div
        class="box-card-course-fake"
        v-for="indexFake in 10"
        :key="indexFake + 'fake'"
      ></div>
    </div>
    <fouter class="fouter_bot"></fouter>
  </div>
</template>

<script>
// @ is an alias to /src
import CourseCard from "@/components/CourseCard.vue";
import fouter from '../../src/components/fouter/fouter'
export default {
  name: "CourseDetailBoundle",
  data() {
    return {
      msg: "CourseDetailBoundle  课程详情——联报",
      goodsList: [],
      //适配类型
      adaptive: ''
    };
  },
  methods: {
    goCourseDetail(item) {
      let ref = this;
      // 跳转至课程详情
      // console.log("go page item.id:" + item.id);
      // if (item.sellType === 1) {
      //   // 联报
      //   ref.$router.push({
      //     path: "/course-detail-boundle",
      //     query: { id: item.id }
      //   });
      // } else {
      //   // item.sellType===2 单网课
      //   ref.$router.push({
      //     path: "/course-detail-single",
      //     query: { id: item.id }
      //   });
      // }

      // boundle 列表页只能一级，列表中点击直接进入单课程详情页面
      ref.$router.push({
        path: "/course-detail-single",
        query: { id: item.id }
      });
    },
    getGoodsList() {
      // 获取当前分类的课程列表
      let ref = this;
      let paramData = {
        ...ref.$route.query,
        userId : ref.$store.state.userInfo.userId
      };
      ref.axios.post("/shop1Api/get/sub/goods/list", paramData).then(res => {
        let data = res.data;
        if (res.data.success) {
          // data
          ref.goodsList = data.entity.goodsList;
        }
      });
    }
  },
  created() {
    this.adaptive = this.$store.state.adaptive
    this.getGoodsList();
  },
  components: {
    CourseCard,
    fouter
  }
};
</script>
<style lang="less" scoped>
html {
  height: 100%;
}
.course-detail-boundle {
  text-align: left;
  padding-top: 20px;
  .boundle-list {
    min-height: calc(100vh - 160px);
    width: 1200px;
    margin: 0 auto;
    height: 100%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    .box-card-course-fake {
      width: 30%;
    }
  }
  .fouter_bot{
    width: 100%;
    height: 160px;
  }
}
.course-detail-boundle-model {
  text-align: left;
  padding-top: 0.2rem;
  .boundle-list {
    //min-height: calc(100vh - 160px);
    width: 98%;
    margin: 0 auto;
    height: 100%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    .box-card-course-fake {
      width: 30%;
    }
  }
  .fouter_bot{
    width: 100%;
    height: 1.6rem;
  }
}
</style>
