<template>
  <div class="sign-in" id="sign-in">
    <!-- <img alt="Vue logo" src="../assets/logo.png" /> -->
    <div v-if="showModal" class="mask" @click="closePop"></div>

    <div :class="adaptive === 'Pc' ? 'Box' : 'Box-model'">
      <div class="header">
        <img
          src="https://ytk-front.oss-cn-beijing.aliyuncs.com/pc/doctorTopic/header/logo.png"
          class="icon"
          alt=""
        />
<!--        <div class="header_font">-->
<!--          <div class="hefont_tit">研大考研</div>-->
<!--          <div class="hefont_bot">考研名师直播课</div>-->
<!--        </div>-->
      </div>
      <div class="phoneNum">
        <el-input
          v-model="form.username"
          maxlength="11"
          placeholder="手机号"
          prefix-icon="el-icon-phone"
          @keyup.enter.native="signIn"
        >
        </el-input>
      </div>
      <div class="possword">
        <el-input
          placeholder="密码"
          prefix-icon="el-icon-lock"
          v-model="form.password"
          maxlength="20"
          show-password
          @keyup.enter.native="signIn"
        ></el-input>
      </div>
      <div class="forgetbox">
        <div class="forgetpass" @click="forgetpassword">忘记密码？</div>
      </div>
      <el-button type="primary" class="confirm" @click="signIn">登录</el-button>
      <div class="register" @click="pushRegister">还没有账号？立即注册</div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import { mapState } from "vuex";
export default {
  name: "SignIn",
  data() {
    return {
      msg: "sign in  登录",
      form: {
        username: "",
        password: "",
        loginType : 'web'
      },
      showModal: true,
      //适配类型
      adaptive : ''
    };
  },
  computed: mapState([
    // 映射 this.count 为 store.state.count
    "isLogin"
  ]),
  methods: {
    closePop(){
      this.$store.commit("signinAppType", 1);
    },
    signIn() {
      let ref = this;
      // 放开登录帐号限制
      // if (
      //   !["18522089327", "18500040836", "16619966754"].includes(
      //     ref.form.username
      //   )
      // ) {
      //   ref.$message({
      //     message: "您的帐号暂不允许登录",
      //     type: "warning"
      //   });
      //   return false;
      // }
      if (!ref.form.username) {
        ref.$message({
          message: "请输入账号",
          type: "warning"
        });
        return false;
      }
      if (ref.form.password.length < 6 || ref.form.password.length > 20) {
        ref.$message({
          message: "密码长度只能为6-20",
          type: "warning"
        });
        return false;
      }
      ref.axios.post("/user1Api/login/new", ref.form).then(res => {
        if (res.data.success) {
          ref.$store.commit("setUserInfo", res.data.entity);
          ref.$store.commit("setLoginState", true);
          ref.$store.commit("signinAppType", 1);
          // 判断失效状态从新回0
          ref.$store.commit("signQueryClear", 0);
          ref.$nextTick(() => {
            ref.$router.push({ path: "/index" });
          });
          this.$message({
            message: '登录成功',
            type: 'success'
          });
        } else {
          ref.$message({
            message: res.data.message,
            type: "warning"
          });
        }
      });
    },
    pushRegister() {
      this.$store.commit("signUpAppType", (this.$store.state.signUpType += 1));
      this.$store.commit("signinAppType", 1);
    },
    forgetpassword() {
      this.$store.commit("signinAppType", 1);
      this.$store.commit("signUpAppType", 1);
      this.$store.commit("signgorgetPass", (this.$store.state.signforget += 1));
    }
  },
  mounted() {
    this.adaptive = this.$store.state.adaptive
    let ref = this;
    // 判断失效状态为1说明session失效
    if (ref.$store.state.querylcer == 1) {
      // 需要重新登陆
      ref.$store.commit("setLoginState", false);
    } else {
      // 已登录
      if (ref.isLogin) {
        ref.$router.push({ path: "/index" });
      }
    }
    // if (ref.$route.query.clear) {
    //   // 需要重新登陆
    //   ref.$store.commit("setLoginState", false);
    // } else {
    //   // 已登录
    //   if (ref.isLogin) {
    //     ref.$router.push({ path: "/index" });
    //   }
    // }
  },
  components: {}
};
</script>
<style lang="less" scoped>
.sign-in {
  height: 100%;
  // width: 100%;
  display: flex;
  // flex-direction: column;
  justify-content: center;
  align-items: center;
  .mask {
    background-color: rgb(0, 0, 0);
    opacity: 0.3;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 99;
  }
  .Box {
    width: 500px;
    height: 471px;
    background: #ffffff;
    // background: #cccccc;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 101;
    .header {
      margin-top: 48px;
      display: flex;
      flex-direction: row;
      align-items: center;
      .icon {
        width: 144px;
        height: 50px;
      }
      .header_font {
        display: flex;
        flex-direction: column;
        margin-left: 14px;
        display: flex;
        align-items: flex-start;
        .hefont_tit {
          font-size: 24px;
          font-family: ZhenyanGB;
          font-weight: bold;
          color: #000000;
        }
        .hefont_bot {
          font-size: 17px;
          font-family: Hiragino Sans GB;
          font-weight: normal;
          color: #000000;
          margin-top: 8px;
        }
      }
    }
    .phoneNum {
      margin-top: 42px;
      width: 433px;
      background: #ffffff;
      border: 1px solid #d4d4d4;
      border-radius: 4px;
    }
    .possword {
      margin-top: 25px;
      width: 433px;
      background: #ffffff;
      border: 1px solid #d4d4d4;
      border-radius: 4px;
    }
    .forgetbox {
      display: flex;
      justify-content: flex-end;
      width: 100%;
      margin-top: 18px;
      .forgetpass {
        font-size: 16px;
        font-family: Hiragino Sans GB;
        font-weight: normal;
        text-decoration: underline;
        color: #707070;
        margin-right: 18px;
        cursor: pointer;
      }
    }
    .confirm {
      width: 433px;
      height: 50px;
      background: #2065ff;
      border: 0;
      border-radius: 4px;
      font-size: 20px;
      font-family: Hiragino Sans GB;
      font-weight: normal;
      color: #ffffff;
      margin-top: 25px;
    }
    .register {
      font-size: 16px;
      font-family: Hiragino Sans GB;
      font-weight: normal;
      text-decoration: underline;
      color: #707070;
      margin-top: 40px;
      cursor: pointer;
    }
  }
  .Box-model {
    width: 6rem;
    height: 6.71rem;
    background: #ffffff;
    // background: #cccccc;
    border-radius: 0.1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 101;
    .header {
      margin-top: 0.48rem;
      display: flex;
      flex-direction: row;
      align-items: center;
      .icon {
        width: 1.6rem;
        height: 0.5rem;
      }
      .header_font {
        display: flex;
        flex-direction: column;
        margin-left: 0.14rem;
        display: flex;
        align-items: flex-start;
        .hefont_tit {
          font-size: 0.24rem;
          font-family: ZhenyanGB;
          font-weight: bold;
          color: #000000;
        }
        .hefont_bot {
          font-size: 0.17rem;
          font-family: Hiragino Sans GB;
          font-weight: normal;
          color: #000000;
          margin-top: 0.08rem;
        }
      }
    }
    .phoneNum {
      margin-top: 0.42rem;
      width: 4.33rem;
      background: #ffffff;
      border: 1px solid #d4d4d4;
      border-radius: 0.04rem;
    }
    .possword {
      margin-top: 0.25rem;
      width: 4.33rem;
      background: #ffffff;
      border: 1px solid #d4d4d4;
      border-radius: 4px;
    }
    .forgetbox {
      display: flex;
      justify-content: flex-end;
      width: 100%;
      margin-top: 0.18rem;
      .forgetpass {
        font-size: 0.16rem;
        font-family: Hiragino Sans GB;
        font-weight: normal;
        text-decoration: underline;
        color: #707070;
        margin-right: 0.18rem;
        cursor: pointer;
      }
    }
    .confirm {
      width: 4.33rem;
      height: 0.8rem;
      background: #2065ff;
      border: 0;
      border-radius: 4px;
      font-size: 0.2rem;
      font-family: Hiragino Sans GB;
      font-weight: normal;
      color: #ffffff;
      margin-top: 0.25rem;
    }
    .register {
      font-size: 0.16rem;
      font-family: Hiragino Sans GB;
      font-weight: normal;
      text-decoration: underline;
      color: #707070;
      margin-top: 0.4rem;
      cursor: pointer;
    }
  }
  .sign-in-box {
    width: 400px;
    padding: 40px 20px 10px;
    background: #fff;
    border-radius: 6px;
  }
}
</style>
