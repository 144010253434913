<template>
  <div :class="adaptive === 'Pc' ? 'tabs-info' : 'tabs-info-model'">
    <div class="tabs-info-content">
      <el-tabs v-model="activeTab" type="card">
        <el-tab-pane label="课程介绍" name="tab1">
          <div v-html="localGoodsDetail.content" :style="adaptive === 'Pc' ? 'display:flex;padding-left:19px;' : 'display:flex;'"></div>
        </el-tab-pane>
        <el-tab-pane label="课程目录" name="tab2">
          <div class="tab2-content">
            <div
              class="free-section-box"
              v-if="localGoodsDetail.isHaveFreeSection"
            >
              <div class="free-section-title">
                <div class="leftBoxColor"></div>
                <div>试听课</div>
              </div>
              <el-table
                :data="localGoodsDetail.freeCourseSectionList"
                style="width: 100%;"
                :show-header="false"
              >
                <el-table-column prop="name" label="课程名称" min-width="adaptive === 'Pc' ? '300px' : ''">
                  <template slot-scope="scope">
                    <div class="child-section-cell">
                      <p class="course-name-box">
                        <span>
                          <el-tag size="mini">试听 </el-tag>
                        </span>
                        <span class="course-name">{{ scope.row.name }}</span>
                        <span style="margin:0 10px;">|</span>
                        <span>{{ scope.row.teacherName }}</span>
                        <!-- <el-tag
                          class="course-tag"
                          size="mini"
                          v-if="scope.row.isLastStudy"
                          >上次学到
                        </el-tag> -->
                      </p>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column prop="handler" label="操作" width="adaptive === 'Pc' ? '180px' : ''">
                  <template slot-scope="scope">
                    <!-- "type" 节点类型 1章 2录播 3直播 <number> -->
                    <template v-if="scope.row.type === 1">
                      <span>{{ scope.row.startTime }}</span>
                    </template>
                    <template>
                      <i
                        class="el-icon-video-play play-btn"
                        @click="goVideo(scope.row)"
                      ></i>
                    </template>
                  </template>
                </el-table-column>
              </el-table>
            </div>
            <el-collapse v-model="activeNames">
              <el-collapse-item
                v-for="(item, index) in localGoodsDetail.courseSectionList"
                :title="item.name"
                :name="index"
                :key="index"
                style="margin-top:20px;"
              >
                <template slot="title">
                  <div class="leftBoxColor"></div>
                  <span class="collapse-item-title">{{ item.name }}</span>
                </template>
                <el-table
                  :data="item.childSections"
                  style="width: 100%"
                  :show-header="false"
                >
                  <el-table-column
                    prop="name"
                    label="课程名称"
                    min-width="adaptive === 'Pc' ? '300px' : ''"
                  >
                    <template slot-scope="scope">
                      <div class="child-section-cell">
                        <p class="course-name-box">
                          <span class="course-name">{{ scope.row.name }}</span>
                          <el-tag
                            class="course-tag"
                            size="mini"
                            v-if="scope.row.isLastStudy"
                            >上次学到</el-tag
                          >
                        </p>

                        <p class="course-info">
                          <span>
                            <el-tag size="mini">
                              {{ getSectionType(scope.row) }}
                            </el-tag>
                          </span>
                          <span style="margin:0 10px;">|</span>
                          <span>{{ scope.row.teacherName }}</span>
                        </p>
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column prop="handler" label="操作" width="adaptive === 'Pc' ? '180px' : ''">
                    <template slot-scope="scope">
                      <!-- "type" 节点类型 1章 2录播 3直播 <number> -->
                      <template v-if="scope.row.type === 1">
                        <span>{{ scope.row.startTime }}</span>
                      </template>
                      <template>
                        <i
                          class="el-icon-video-play play-btn"
                          @click="goVideo(scope.row)"
                        ></i>
                      </template>
                    </template>
                  </el-table-column>
                </el-table>
              </el-collapse-item>
            </el-collapse>
          </div>
        </el-tab-pane>
        <el-tab-pane label="老师介绍" name="tab3">
          <div class="tab3-content">
            <el-table
              :data="localGoodsDetail.teacherList"
              style="width: 100%;margin-top:30px;"
              :show-header="false"
            >
              <el-table-column prop="photo" label="头像" width="adaptive === 'Pc' ? '200px' : '2rem'">
                <template slot-scope="scope">
                  <el-image
                    :style="adaptive === 'Pc' ? 'width: 170px; height: 170px' : 'width: 1.7rem; height: 1.7rem'"
                    :src="
                      'https://ytk-img.oss-cn-beijing.aliyuncs.com' +
                        scope.row.photo
                    "
                    fit="fill"
                  ></el-image>
                </template>
              </el-table-column>
              <el-table-column prop="handler" label="介绍" min-width="200px">
                <template slot-scope="scope">
                  <p class="teacher-name-title">
                    <span class="teacher-name">{{ scope.row.name }}</span>
                    <span class="teacher-title">{{ scope.row.title }}</span>
                  </p>
                  <p class="teacher-info">{{ scope.row.info }}</p>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </el-tab-pane>
        <el-tab-pane label="学生评价" name="tab4">
          <div class="tab4-content">
            <el-table
              :data="commentList"
              style="width: 100%;"
              :show-header="false"
            >
              <el-table-column prop="photo" label="头像" width="50px">
                <template slot-scope="scope">
                  <el-image
                    style="width: 40px; height: 40px"
                    :src="
                      'https://yd-kc-img.oss-cn-beijing.aliyuncs.com' +
                        scope.row.avatar
                    "
                    fit="fill"
                  ></el-image>
                </template>
              </el-table-column>
              <el-table-column prop="handler" label="介绍" min-width="200px">
                <template slot-scope="scope">
                  <p class="student-name-time">
                    <span class="student-name">{{ scope.row.username }}</span>
                    <span class="comment-time">{{
                      scope.row.createTimeStr
                    }}</span>
                  </p>
                  <p class="comment-info" style="font-size:14px;width:95%;">
                    {{ scope.row.content }}
                  </p>
                </template>
              </el-table-column>
            </el-table>
            <div
              style="text-align:center; padding-top:20px;"
              v-show="!noMoreComment"
            >
              <el-button type="text" @click="loadMoreComment"
                >加载更多...</el-button
              >
            </div>
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>
<script>
// import signIn from "@/views/SignIn.vue";
export default {
  name: "TabsInfo",
  props: {
    goodsDetail: Object
  },
  data() {
    return {
      msg: "tabs-info 课程详情-课程信息",
      localGoodsDetail: this.goodsDetail,
      activeTab: "tab1",
      // 添加试听课之后不再默认展开第一章节
      // activeNames: [0],
      activeNames: [],
      commentList: [],
      page: {
        currentPage: 1,
        pageSize: 10,
        total: 0
      },
      noMoreComment: false,
      typeEnum: {
        "1": "文件",
        "2": "录播",
        "3": "直播"
      },
      liveStatusEnum: {
        start: "未开始",
        on: "正在直播",
        end: "已结束",
        back: "回放"
      },
      //适配类型
      adaptive : ''
    };
  },
  computed: {},
  methods: {
    getSectionType(row) {
      let ref = this;
      let type = "";
      if (row.type === 3) {
        type = ref.liveStatusEnum[row.liveStatus];
      } else {
        type = ref.typeEnum[row.type];
      }

      return type;
    },
    loadMoreComment() {
      this.page.currentPage++;
      this.getComments(true);
    },
    getComments(isMore) {
      let ref = this;
      let paramData = {
        type: "goods",
        otherId: ref.localGoodsDetail.courseId,
        "page.currentPage": ref.page.currentPage,
        "page.pageSize": ref.page.pageSize
      };
      ref.axios.post("/user1Api/new/comment/list", paramData).then(res => {
        let data = res.data;
        if (res.data.success) {
          // data
          if (isMore && !data.entity.commentList.length) {
            ref.$message({
              message: "没有更多评论数据了~。~",
              type: "warning"
            });
            ref.noMoreComment = true;
          } else {
            ref.commentList = ref.commentList.concat(data.entity.commentList);
          }
        }
      });
    },
    goRecordedBroadcast(item, data) {
      let ref = this;
      ref.$router.push({
        path: "/video-page",
        query: {
          sectionId: item.IdStr,
          sectionName: item.name,
          v: data.entity.sectionInfo.vid,
          c: data.entity.sectionInfo.code,
          s: data.entity.sectionInfo.playSafe,
          ts: data.entity.sectionInfo.ts,
          sign: data.entity.sectionInfo.sign
        }
      });
    },
    goVideo(item) {
      let ref = this;
      // id已经在请求接口时暴露无需base64混淆处理
      // let salt = ref.rondomPass(5);
      // 跳转至课程详情
      // console.log("item.id", item);
      let paramData = {
        sectionId: item.IdStr
      };
      ref.axios.post("/shop1Api/check/course/section", paramData).then(res => {
        let data = res.data;
        if (data.success) {
          if (data.entity.isOk) {
            // 根据sectionInfo 判断是直播还是点播
            if (data.entity.sectionInfo) {
              // 录播
              ref.goRecordedBroadcast(item, data);
            } else {
              window.open(data.entity.liveUrl, "_blank");
            }
          } else {
            // 未购买
            ref.$message({
              message: "请先购买课程",
              type: "warning"
            });
          }
        } else {
          // 接口异常
          ref.$message({
            message: data.message,
            type: "warning"
          });
        }
      });
    },
    goVideoBak(item) {
      let ref = this;
      // id已经在请求接口时暴露无需base64混淆处理
      // let salt = ref.rondomPass(5);
      // 跳转至课程详情
      // console.log("item.id", item);
      let paramData = {
        sectionId: item.IdStr
      };
      ref.axios.post("/shop1Api/check/course/section", paramData).then(res => {
        let data = res.data;
        if (data.success) {
          if (data.entity.isOk) {
            // 已购买 (type 2 录播 3直播)
            if (data.entity.type === 2) {
              // 录播
              ref.goRecordedBroadcast(item, data);
            } else if (data.entity.type === 3) {
              // 直播
              // "liveStatus": "start"直播状态 start 未开始 on 正在直播 end 已结束 back 已结束有回放
              if (data.entity.liveStatus === "start") {
                ref.$message({
                  message: `直播尚未开始，开始时间: ${item.startTime}`,
                  type: "warning"
                });
                return false;
              } else if (data.entity.liveStatus === "end") {
                ref.$message({
                  message: `直播已结束，暂无回放视频`,
                  type: "warning"
                });
                return false;
              } else if (data.entity.liveStatus === "on") {
                ref.$router.push({
                  path: "/live-page",
                  query: {
                    channelId: data.entity.sectionInfo.channelId,
                    sign: data.entity.sectionInfo.sign,
                    timestamp: data.entity.sectionInfo.timestamp,
                    appId: data.entity.sectionInfo.appId,
                    userId: data.entity.user.userId,
                    userName: data.entity.user.userName,
                    pic:
                      "https://yd-kc-img.oss-cn-beijing.aliyuncs.com" +
                      data.entity.user.pic,
                    roomId: data.entity.sectionInfo.channelId,
                    nick: data.entity.user.userName,
                    token: data.entity.sectionInfo.token,
                    mediaChannelKey: data.entity.sectionInfo.mediaChannelKey,
                    scene: data.entity.sectionInfo.scene,
                    sectionName: item.name
                  }
                });
              } else if (data.entity.liveStatus === "back") {
                // 直播结束可观看录播
                ref.goRecordedBroadcast(item, data);
              }
            } else {
              console.warning("未知媒体类型");
            }
          } else {
            // 未购买
            ref.$message({
              message: "请先购买课程",
              type: "warning"
            });
          }
        } else {
          // 接口异常
          ref.$message({
            message: data.message,
            type: "warning"
          });
        }
      });
    }
  },
  created() {
    this.adaptive = this.$store.state.adaptive
    this.getComments();
    // 如果没有试听课程默认展开课程目录第一章节
    if (!this.localGoodsDetail.isHaveFreeSection) {
      this.activeNames = [0];
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.tabs-info {
  background-color: #fff;
  padding: 20px;
  width: 1200px;
  margin: 0 auto;
  p {
    margin: 0;
    padding: 0;
  }
  .tabs-info-content {
    display: block;
    .tab2-content {
      .leftBoxColor {
        width: 6px;
        height: 19px;
        background: #2065ff;
        margin-right: 27px;
      }
      .collapse-item-title {
        font-size: 20px;
      }
      .child-section-cell {
        // margin-top: 22px;
        .course-name-box {
          .course-name {
            font-size: 14px;
            font-family: Hiragino Sans GB;
            font-weight: normal;
            color: #000000;
            line-height: 42px;
          }
          .course-tag {
            margin-left: 20px;
          }
        }
      }
      .play-btn {
        cursor: pointer;
        font-size: 24px;
        &:hover {
          color: #00cba9;
        }
      }
      .free-section-box {
        .free-section-title {
          font-size: 20px;
          text-align: left;
          margin-top: 29px;
          display: flex;
          flex-direction: row;
          align-items: center;
          .leftBoxColor {
            width: 6px;
            height: 19px;
            //background: #2065ff;
            margin-right: 27px;
          }
        }

        .child-section-cell {
          .course-name-box {
            .course-name {
              font-size: 18px;
              margin-left: 10px;
            }
            .course-tag {
              margin-left: 20px;
            }
          }
          .course-info {
            margin-top: 10px;
            margin-bottom: 10px;
          }
        }
        .play-btn {
          cursor: pointer;
          font-size: 24px;
          &:hover {
            color: #00cba9;
          }
        }
      }
    }
    .tab3-content {
      .teacher-name-title {
        margin-bottom: 20px;
        font-family: Adobe Heiti Std;
        font-weight: normal;
        color: #000000;
        line-height: 42px;
        .teacher-name {
          font-size: 26px;
          font-weight: bold;
        }
        .teacher-title {
          margin-left: 18px;
        }
      }
      .teacher-info {
        font-size: 18px;
        font-family: Hiragino Sans GB;
        font-weight: normal;
        color: #000000;
        line-height: 25px;
      }
    }
    .tab4-content {
      .student-name-time {
        .student-name {
          font-size: 16px;
          font-weight: bold;
          font-family: Adobe Heiti Std;
          font-weight: normal;
          color: #000000;
          line-height: 42px;
        }
        .comment-time {
          float: right;
          font-size: 14px;
          font-family: Adobe Heiti Std;
          font-weight: normal;
          color: #b3b3b3;
          line-height: 42px;
        }
      }
    }
  }
}
.tabs-info-model {
  background-color: #fff;
  //padding: 0.2rem;
  //width: 100%;
  box-sizing: border-box;
  margin: 0 auto;
  p {
    margin: 0;
    padding: 0;
  }
  .tabs-info-content {
    display: block;
    .tab2-content {
      .leftBoxColor {
        width: 0.06rem;
        height: 0.19rem;
        background: #2065ff;
        margin-right: 0.27rem;
      }
      .collapse-item-title {
        font-size: 0.2rem;
      }
      .child-section-cell {
        // margin-top: 22px;
        .course-name-box {
          .course-name {
            font-size: 0.14rem;
            font-family: Hiragino Sans GB;
            font-weight: normal;
            color: #000000;
            line-height: 0.42rem;
          }
          .course-tag {
            margin-left: 0.2rem;
          }
        }
      }
      .play-btn {
        cursor: pointer;
        font-size: 0.24rem;
        &:hover {
          color: #00cba9;
        }
      }
      .free-section-box {
        .free-section-title {
          font-size: 0.2rem;
          text-align: left;
          margin-top: 0.29rem;
          display: flex;
          flex-direction: row;
          align-items: center;
          .leftBoxColor {
            width: 0.06rem;
            height: 0.19rem;
            background: #2065ff;
            margin-right: 0.27rem;
          }
        }

        .child-section-cell {
          .course-name-box {
            .course-name {
              font-size: 0.18rem;
              margin-left: 0.1rem;
            }
            .course-tag {
              margin-left: 0.2rem;
            }
          }
          .course-info {
            margin-top: 0.1rem;
            margin-bottom: 0.1rem;
          }
        }
        .play-btn {
          cursor: pointer;
          font-size: 0.24rem;
          &:hover {
            color: #00cba9;
          }
        }
      }
    }
    .tab3-content {
      .teacher-name-title {
        margin-bottom: 0.2rem;
        font-family: Adobe Heiti Std;
        font-weight: normal;
        color: #000000;
        line-height: 0.42rem;
        .teacher-name {
          font-size: 0.26rem;
          font-weight: bold;
        }
        .teacher-title {
          margin-left: 0.18rem;
        }
      }
      .teacher-info {
        font-size: 0.18rem;
        font-family: Hiragino Sans GB;
        font-weight: normal;
        color: #000000;
        line-height: 0.25rem;
      }
    }
    .tab4-content {
      .student-name-time {
        .student-name {
          font-size: 0.16rem;
          font-weight: bold;
          font-family: Adobe Heiti Std;
          font-weight: normal;
          color: #000000;
          line-height: 0.42rem;
        }
        .comment-time {
          float: right;
          font-size: 0.14rem;
          font-family: Adobe Heiti Std;
          font-weight: normal;
          color: #b3b3b3;
          line-height: 0.42rem;
        }
      }
    }
  }
}
</style>
<style>
.el-tabs__nav{
  border: 0 !important;
}
.el-tabs__item{
  display: flex;
  border: 0 !important;
  padding: 0 17px !important;
}
.el-tabs__item:hover{
  color: #3399FF !important;
}
.el-tabs--card>.el-tabs__header{
  border: 0 !important;
}
.el-tabs__item.is-active{
  color: #3399FF !important;
}

</style>
