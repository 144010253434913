<template>
  <div id="app" class="app">
    <Layout msg="layout" class="app_header"/>
    <!-- 登录注册 -->
    <sign-in v-if="siginIntype" class="fiexedPos"></sign-in>
    <sign-up v-if="siginUptype" class="fiexedPos"></sign-up>
    <SignFo v-if="signFootype" class="fiexedPos"></SignFo>
  </div>
</template>

<script>
import SignIn from "@/views/SignIn";
import SignUp from "@/views/SignUp";
import SignFo from "@/views/forgetPassword/forgetPass";
import Layout from "./components/Layout.vue";

export default {
  name: "app",
  components: {
    Layout,
    SignIn,
    SignUp,
    SignFo,
  },
  data() {
    return {
      siginIntype: false,
      siginUptype: false,
      signFootype: false
    };
  },
  computed: {
    sigintype() {
      return this.$store.state.signinType;
    },
    signUpType() {
      return this.$store.state.signUpType;
    },
    signforget(){
      return this.$store.state.signforget;
    }
  },
  watch: {
    sigintype(v) {
      this.siginIntype = v && v > 1
    },
    signUpType(v) {
      this.siginUptype = v && v > 1
    },
    signforget(v) {
      this.signFootype = v && v > 1
    }
  },
};
</script>

<style>
html,
body {
  padding: 0;
  overflow: hidden;
}

#app {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

.fiexedPos {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99;
}
</style>
<style scoped lang="less">
.app_header {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
}

.fouter_tit {
  position: fixed;
  bottom: 0;
}
</style>