"use strict";

import qs from "qs";
import Vue from "vue";
import axios from "axios";
import store from '../store/index'
// import router from "../router";
import {MessageBox} from "element-ui";
import md5 from 'js-md5'

// Full config:  https://github.com/axios/axios#request-config
// axios.defaults.baseURL = process.env.baseURL || process.env.apiUrl || '';
// axios.defaults.headers.common['Authorization'] = AUTH_TOKEN;
// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';

function randomString(length) {
    let str = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
    let result = '';
    for (var i = length; i > 0; --i)
        result += str[Math.floor(Math.random() * str.length)];
    return result;
}

let config = {
    // baseURL: process.env.baseURL || process.env.apiUrl || ""
    // timeout: 60 * 1000, // Timeout
    withCredentials: true // Check cross-site Access-Control
};

const _axios = axios.create(config);

_axios.interceptors.request.use(
    function (config) {
        config.headers = {'Content-Type': 'application/x-www-form-urlencoded'}
        let timestamp = Date.parse(new Date());
        let nonce_str = randomString(6)
        let produceId = ''
        let token = ''
        //判断url添加不同的参数
        if (config.url.indexOf('user1Api') !== -1){
            produceId = 'product_user'
            token = 'rbGAVcJIABFft0FsIQL4wJtmY2LbQqKV'
        }else if (config.url.indexOf('main1Api') !== -1){
            produceId = 'product_web'
            token = 'fxQipHk3pZa0q5i9MxBlwO1bhM+6lqQW'
        }else if (config.url.indexOf('shop1Api') !== -1){
            produceId = 'product_shop'
            token = 'cum4VNfgjRDcvSPJSDCvRhb9172Lieax'
        }
        let secret = md5('productId='+produceId+'&token='+token+'&nonce_str=' + nonce_str + '&timestamp=' + timestamp)
        let secret1 = 'productId='+produceId+'&token='+token+'&nonce_str=' + nonce_str + '&timestamp=' + timestamp
        console.log(secret,secret1, '>>>>>>>>>>>>>>>>111')

        config.data.secret = secret
        config.data.nonce_str = nonce_str
        config.data.timestamp = timestamp
        // config.data.types = types
        // Do something before request is sent
        // 将请求转为form 表单形式提交,修改data格式，将自动变更Content-Type为=》  Content-Type: application/x-www-form-urlencoded
        config.data = qs.stringify(config.data);
        return config;
    },
    function (error) {
        // Do something with request error
        return Promise.reject(error);
    }
);

// Add a response interceptor
_axios.interceptors.response.use(
    function (response) {
        // Do something with response data
        // console.log("response", response);
        if (!response.success && response.data.message === "请登录") {
            MessageBox.alert("登录信息过期或未登录，请先登录", "提示", {
                confirmButtonText: "确定",
                showClose: false,
                center: true,
                callback: () => {

                    // 跳转登录  app首页全局组件 把query参数换成state的值 signin mounted里边判断 不走query参数了
                    store.commit('signinAppType', store.state.signinType += 1)
                    store.commit('signQueryClear', 1)

                    // if (router.currentRoute.path !== "/sign-in") {
                    //   router.push({
                    //     path: "/sign-in",
                    //     query: { clear: 1 }
                    //   });
                    // }
                }
            });
        }
        return response;
    },
    function (error) {
        // Do something with response error
        return Promise.reject(error);
    }
);

Plugin.install = function (Vue) {
    Vue.axios = _axios;
    window.axios = _axios;
    Object.defineProperties(Vue.prototype, {
        axios: {
            get() {
                return _axios;
            }
        },
        $axios: {
            get() {
                return _axios;
            }
        }
    });
};

Vue.use(Plugin);

export default Plugin;
