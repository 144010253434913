<template>
  <div class="user-course-detail" id="user-course-detail">
    <div :class="adaptive === 'Pc' ? 'index-page' : 'index-page-model'">
      <div class="course-base-info">
        <div class="info-left">
          <p class="course-name">{{ course.name }}</p>
        </div>
        <div class="info-right">
          <el-tag class="time-tag" effect="dark">
            <el-row>
              <el-col :span="12">
                <div class="time-item">
                  <p class="time-num">{{ course.studyHour }}</p>
                  <p class="time-label">已完成课程</p>
                </div>
              </el-col>
              <el-col :span="12">
                <div class="time-item">
                  <p class="time-num">{{ course.classHour }}</p>
                  <p class="time-label">全部课程</p>
                </div>
              </el-col>
            </el-row>
          </el-tag>
        </div>
      </div>
      <div class="tabs-box">
        <el-tabs @tab-click="handleClick" v-model="activeName">
          <el-tab-pane
              class="today-course-tab-con"
              label="今日任务"
              name="todayCourseListTab"
              style="color:red;"
          >
            <el-table
                :data="todayCourseList"
                :show-header="false"
                style="width: 100%"
            >
              <el-table-column label="课程名称" :min-width="adaptive === 'Pc' ? '200px' : ''" prop="name">
                <template slot-scope="scope">
                  <div class="today-cell">
                    <p class="course-name">{{ scope.row.name }}</p>
                    <p class="course-info">
                    <span>
                      <el-tag size="mini">{{
                          getSectionType(scope.row)
                        }}</el-tag>
                    </span>
                      <span style="margin:0 10px;">|</span>
                      <span>{{ scope.row.teacherName }}</span>
                    </p>
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="操作" prop="handler" :width="adaptive === 'Pc' ? '100px' : ''">
                <template slot-scope="scope">
                  <el-button
                      @click="goVideo(scope.row)"
                      size="small"
                      type="primary"
                  >开始学习</el-button
                  >
                </template>
              </el-table-column>
            </el-table>
          </el-tab-pane>
          <el-tab-pane
              class="section-list-tab-con"
              label="课程目录"
              name="sectionListTab"
          >
            <el-collapse v-model="activeCollapse">
              <el-collapse-item
                  :key="index"
                  :name="index"
                  :title="item.name"
                  class="section-collapse-item"
                  v-for="(item, index) in sectionList"
              >
                <el-collapse v-model="activeNames" @change="handleChange">
                  <el-collapse-item :name="`${index}${indexs}`"  :key="indexs" v-for="(items, indexs) in item.childSections">
                    <template slot="title">
                      <div class="title-slot">
                        <div class="section-name-box">
                          <div class="color_block">
                            <div class="color-green"></div>
                            <span class="section-name"> {{ items.name }}</span>
                          </div>
                          <el-tag
                              class="section-tag"
                              size="mini"
                              v-if="items.isLastStudy"
                          >上次学到
                          </el-tag
                          >
                        </div>
                        <div class="progress-box">
                          <el-progress :percentage="getProgress(items)"></el-progress>
                        </div>
                      </div>
                    </template>
                    <el-table
                        :data="items.childSections"
                        :show-header="false"
                        style="width: 100%"
                    >
                      <el-table-column label="课程名称" min-width="200px" prop="name">
                        <template slot-scope="scope">
                          <div class="child-section-cell">
                            <p class="course-name-box">
                                            <span class="course-name"
                                            >{{ scope.row.courseName }}</span
                                            >
                              <el-tag
                                  class="course-tag"
                                  size="mini"
                                  v-if="scope.row.isLastStudy"
                              >上次学到
                              </el-tag
                              >
                            </p>

                            <p class="course-info">
                                            <span>
                                              <el-tag size="mini">{{
                                                  getSectionType(scope.row)
                                                }}</el-tag>
                                            </span>
                              <span style="margin:0 10px;">|</span>
                              <span style="color:#000000;">{{
                                  scope.row.name
                                }}</span>
                              <span
                                  style="margin:0 10px; color:#67C23A;"
                                  v-if="scope.row.studyStatus === 2"
                              >
                                              <i class="el-icon-success"></i>
                                            </span>
                            </p>
                          </div>
                        </template>
                      </el-table-column>
                      <el-table-column label="操作" prop="handler" width="180px">
                        <template slot-scope="scope">
                          <div class="hidden-xs-only">
                            <el-button
                                @click="goCommentPage(scope.row)"
                                plain
                                size="small"
                                style="font-size:14px;color:#333333;"
                            >评论{{ scope.row.commentNum }}
                            </el-button
                            >
                            <el-button
                                @click="goVideo(scope.row)"
                                size="small"
                                type="primary"
                            >开始学习
                            </el-button
                            >
                          </div>
                          <div class="hidden-sm-and-up">
                            <el-button
                                @click="goCommentPage(scope.row)"
                                plain
                                size="small"
                            >评论{{ scope.row.commentNum }}
                            </el-button
                            >
                          </div>
                          <div class="hidden-sm-and-up" style="margin-top:10px;">
                            <el-button
                                @click="goVideo(scope.row)"
                                size="small"
                                type="primary"
                            >开始学习
                            </el-button
                            >
                          </div>
                        </template>
                      </el-table-column>
                    </el-table>
                  </el-collapse-item>
                </el-collapse>
              </el-collapse-item>
            </el-collapse>
          </el-tab-pane>
          <el-tab-pane
              class="course-teach-tab-con"
              label="课程服务"
              name="courseTeachTab"
          >
            <!-- :show-header="false" -->
            <el-table :data="courseTeachList" style="width: 100%">
              <el-table-column label="附件名称" :min-width="adaptive === 'Pc' ? '300px' : ''" prop="name">
                <template slot-scope="scope">
                  <p class="source-name">
                    <span>{{ scope.row.name}}</span>
                  </p>
                </template>
              </el-table-column>
              <el-table-column label="操作" prop="handler" :width="adaptive === 'Pc' ? '180px' : ''">
                <template slot-scope="scope">
                  <el-button
                      @click="getRar(scope.row.teachPng)"
                      size="small"
                      type="primary"
                  >点击下载</el-button
                  >
                </template>
              </el-table-column>
            </el-table>
            <el-card class="box-card-serve box-card-serve-express" shadow="hover">
              <div slot="header" class="clearfix">
                <span>快递信息</span>
              </div>
              <template v-if="infoBak.isSend">
                <p>快递单号:{{ infoBak.requestId }}</p>
                <el-button @click="goExpress" size="small" type="primary"
                >查快递</el-button
                >
              </template>
              <template v-else>
                <p>未查找到快递信息,可能是快递尚未发出哦~</p>
              </template>
            </el-card>
            <el-card class="box-card-serve box-card-serve-qq" shadow="hover">
              <div slot="header" class="clearfix">
                <span>班级QQ群</span>
              </div>
              <template v-if="infoBak.course && infoBak.course.qq">
                <p>QQ群号:{{ infoBak.course.qq }}</p>
              </template>
              <template v-else>
                <p>该班级暂未设置qq群~</p>
              </template>
            </el-card>
            <el-card class="box-card-serve box-card-serve-wx" shadow="hover">
              <div slot="header" class="clearfix">
                <span>课程公众号</span>
              </div>
              <template v-if="infoBak.course && infoBak.course.publicUrl">
                <el-image
                    style="width: 200px; height: 200px"
                    :src="infoBak.course.publicUrl"
                    :fit="fit"
                ></el-image>
              </template>
              <template v-else>
                <p>暂无课程公众号~</p>
              </template>
            </el-card>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
    <fouter class="fouter_bot"></fouter>
  </div>
</template>

<script>
// @ is an alias to /src
// import CourseCard from "@/components/CourseCard.vue";
import fouter from '../../src/components/fouter/fouter'
export default {
  name: "UserCourseDetail",
  data() {
    return {
      msg: "UserCourseDetail  我的课程_课程详情",
      courseList: [], //课程列表
      todayCourse: [], //今日课程
      typeEnum: {
        "1": "文件",
        "2": "录播",
        "3": "直播"
      },
      liveStatusEnum: {
        start: "未开始",
        on: "正在直播",
        end: "已结束",
        back: "回放"
      },
      course: {
        name: ""
      }, //当前课程基本属性
      activeName: "todayCourseListTab",
      infoBak: {},
      todayCourseList: [], //当前课程今日任务
      sectionList: [], //课程目录
      courseTeach: null, //课程附件列表
      activeCollapse: [0], //被展开的课程目录（默认展开第一章节）
      //适配类型
      adaptive: '',
      //有原来2层修改为三层table  被展开的课程目录 默认展开第一章节的第一章 两个索引项拼接成唯一值 index indexs
      activeNames: ['00']
    };
  },
  computed: {
    courseTeachList() {
      let ref = this;
      return ref.courseTeach ? ref.courseTeach : [];
    },
    todayCourseConverted() {
      let ref = this;
      let result = [];
      ref.todayCourse.forEach(ele => {
        result = result.concat(ele.sectionModelList);
      });
      return result;
    }
  },
  methods: {
    handleChange(val) {
      console.log(val);
    },
    goExpress() {
      let ref = this;
      window.open(ref.infoBak.expressUrl, "_blank");
    },
    getSectionType(row) {
      let ref = this;
      let type = "";
      if (row.type === 3) {
        type = ref.liveStatusEnum[row.liveStatus];
      } else {
        type = ref.typeEnum[row.type];
      }

      return type;
    },
    getProgress(row) {
      if (!row.classHour || !row.finishHour) {
        return 0;
      }
      return +((row.finishHour / row.classHour) * 100).toFixed(1);
    },
    handleClick(item) {
      console.log("tabs change", item);
    },
    getRar(url) {
      window.open(
        "https://yd-kc-file.oss-cn-beijing.aliyuncs.com" + url,
        "_blank"
      );
    },
    goCommentPage(sectionData) {
      let ref = this;
      console.log(sectionData,'>>>>>>>>>')
      if (sectionData.commentNum) {
        ref.$router.push({
          path: "/comment-page",
          query: {
            courseId: sectionData.courseId, //课程id
            otherId: sectionData.idStr, //章节id
            courseName: sectionData.courseName,
            name: sectionData.name
          }
        });
      } else {
        ref.$message({
          message: "当前章节暂无评论",
          type: "warning"
        });
      }
    },
    goRecordedBroadcast(item, data) {
      console.log(data,'>>>>>>>>')
      //1百家云 0 保利威
      if (data.entity.platform === 1){
        //百家云
        let ref = this;
        ref.$router.push({
          path: "/onDemandVideo",
          query: {
            sectionId: item.IdStr,
            sectionName: item.name,
            token: data.entity.token,
            vid: data.entity.videoId,
          }
        });
      }else{
        // 保利威
        let ref = this;
        ref.$router.push({
          path: "/video-page",
          query: {
            sectionId: item.IdStr,
            sectionName: item.name,
            v: data.entity.sectionInfo.vid,
            c: data.entity.sectionInfo.code,
            s: data.entity.sectionInfo.playSafe,
            ts: data.entity.sectionInfo.ts,
            sign: data.entity.sectionInfo.sign
          }
        });
      }
    },
    goVideo(item) {
      let ref = this;
      // 跳转至课程详情
      // console.log("item.id", item);
      let paramData = {
        sectionId: item.idStr,
        userId: ref.$store.state.userInfo.userId
      };
      ref.axios.post("/shop1Api/check/course/section/v1", paramData).then(res => {
        let data = res.data;
        if (data.success) {
          // type 3直播 2点播 1回访
          if (data.entity.isOk) {
            //platform 0 保利威 1百家云
            // type 3直播 2点播 1回放  1 3直接跳走
            if (data.entity.platform === 1 && data.entity.type === 2 || data.entity.platform === 0 && data.entity.sectionInfo){
              ref.goRecordedBroadcast(item, data);
            }else if (data.entity.platform === 1 && data.entity.type === 3){
              // back 回放 on 直播中
              if (data.entity.liveStatus === 'back'){
                //sessionId 有说明是长期房间 否则不是长期房间
                if (data.entity.sessionId){
                  let url = `http://${data.entity.private_domain}.at.baijiayun.com/web/playback/index?classid=${data.entity.roomId}&session_id=${data.entity.sessionId}&token=${data.entity.roomToken}`
                  window.open(url);
                }else {
                  let url = `http://${data.entity.private_domain}.at.baijiayun.com/web/playback/index?classid=${data.entity.roomId}&token=${data.entity.roomToken}`
                  window.open(url);
                }
              }else if (data.entity.liveStatus === 'on'){
                let url = `http://${data.entity.private_domain}.at.baijiayun.com/web/room/enter?room_id=${data.entity.roomId}&user_number=${this.$store.state.userInfo.userId}&user_name=${data.entity.nickName}&user_role=${data.entity.userRole}&user_avatar=${data.entity.avatar}&sign=${data.entity.sign}`
                window.open(url);
              }else if (data.entity.liveStatus === 'end'){
                this.$message({
                  message: '直播已结束',
                  type: 'warning'
                });
              }else if (data.entity.liveStatus === 'start'){
                this.$message({
                  message: '直播暂未开始',
                  type: 'warning'
                });
              }
            }else{
              window.open(data.entity.liveUrl, "_blank");
            }
          } else {
            // 未购买
            ref.$message({
              message: "请先购买课程",
              type: "warning"
            });
          }
        } else {
          // 接口异常
          ref.$message({
            message: data.message,
            type: "warning"
          });
        }
      });
    },
    getCourseDetail() {
      // 获取当前分类的课程列表
      let ref = this;
      let paramData = {
        ...ref.$route.query,
        userId: ref.$store.state.userInfo.userId
        // courseId: 45311
      };
      ref.axios.post("/shop1Api/course/info", paramData).then(res => {
        let data = res.data;
        if (res.data.success) {
          // data
          ref.infoBak = data.entity; //数据备份
          ref.course = data.entity.course; //当前课程基本属性
          ref.todayCourseList = data.entity.todayCourseList; //当前课程今日任务
          ref.sectionList = data.entity.sectionList; //课程目录
          ref.courseTeach = data.entity.sectionTeachList; //课程附件列表
          // 如果当前课程今日任务为空，跳转至课程目录
          if (!ref.todayCourseList.length) {
            ref.activeName = "sectionListTab";
          }
        }
      });
    }
  },
  created() {
    this.adaptive = this.$store.state.adaptive
    this.getCourseDetail();
  },
  components: {
    // CourseCard
    fouter
  }
};
</script>
<style lang="less" scoped>
.user-course-detail {
  text-align: left;
  padding-top: 70px;
  p {
    margin: 0;
    padding: 0;
  }
  .index-page{
    min-height: calc(100vh - 160px);
    width: 1200px;
    margin: 0 auto;
    .course-base-info {
      padding: 20px;
      background-color: #fff;
      display: flex;
      .info-left {
        flex: auto;
        .course-name {
          font-size: 24px;
          font-weight: bold;
          font-family: Adobe Heiti Std;
          color: #000000;
        }
      }
      .info-right {
        flex: 0 0 200px;
        .time-tag {
          width: 252px;
          height: 110px;
          border-radius: 11px;
          .time-item {
            text-align: center;
            .time-num {
              font-size: 54px;
              margin: 25px 0 11px 0;
              font-family: Microsoft YaHei;
              font-weight: bold;
              color: #ffffff;
            }
            .time-label {
              font-size: 16px;
              font-family: Microsoft YaHei;
              font-weight: bold;
              color: #ffffff;
            }
          }
        }
      }
    }
    .tabs-box {
      background-color: #fff;
      margin-top: 20px;
      padding: 20px;
      .today-course-tab-con {
        .today-cell {
          .course-name {
            font-size: 18px;
            font-family: Adobe Heiti Std;
            color: #000000;
          }
          .course-info {
            margin-top: 10px;
            margin-bottom: 10px;
          }
        }
      }
      .section-list-tab-con {
        .section-collapse-item {
          .title-slot {
            flex: 0 1 80%;
            .section-name-box {
              display: flex;
              flex-direction: row;
              align-items: center;
              .color_block {
                display: flex;
                flex-direction: row;
                align-items: center;
                .color-green {
                  width: 6px;
                  height: 19px;
                  background: #00CBA9;
                  margin-right: 21px;
                }
              }
              .section-name {
                font-size: 20px;
                font-weight: bold;
              }
              .section-tag {
                margin-left: 20px;
              }
            }
            .progress-box {
              padding: 10px 0;
            }
          }
          .child-section-cell {
            .course-name-box {
              .course-name {
                font-size: 18px;
                font-family: Adobe Heiti Std;
                font-weight: normal;
                color: #000000;
              }
              .course-tag {
                margin-left: 20px;
              }
            }
            .course-info {
              margin-top: 10px;
              margin-bottom: 10px;
            }
          }
        }
      }
      .course-teach-tab-con {
        .source-name {
          font-size: 18px;
        }
        .box-card-serve {
          &.box-card-serve-express {
            margin-top: 20px;
          }
          &.box-card-serve-qq {
            margin-top: 20px;
          }
          &.box-card-serve-wx {
            margin-top: 20px;
          }
        }
      }
    }
  }
  .index-page-model{
    min-height: calc(100vh - 160px);
    width: 100%;
    margin: 0 auto;
    .course-base-info {
      padding: 0.2rem;
      background-color: #fff;
      display: flex;
      .info-left {
        flex: auto;
        .course-name {
          font-size: 0.24rem;
          font-weight: bold;
          font-family: Adobe Heiti Std;
          color: #000000;
        }
      }
      .info-right {
        flex: 0 0 2rem;
        .time-tag {
          width: 3.52rem;
          height: 1.5rem;
          border-radius: 0.11rem;
          .time-item {
            text-align: center;
            .time-num {
              font-size: 0.54rem;
              margin: 0.15rem 0 0.11rem 0;
              font-family: Microsoft YaHei;
              font-weight: bold;
              color: #ffffff;
            }
            .time-label {
              font-size: 0.16rem;
              font-family: Microsoft YaHei;
              font-weight: bold;
              color: #ffffff;
            }
          }
        }
      }
    }
    .tabs-box {
      background-color: #fff;
      margin-top: 0.2rem;
      padding: 0.2rem;
      .today-course-tab-con {
        .today-cell {
          .course-name {
            font-size: 0.18rem;
            font-family: Adobe Heiti Std;
            color: #000000;
          }
          .course-info {
            margin-top: 0.1rem;
            margin-bottom: 0.1rem;
          }
        }
      }
      .section-list-tab-con {
        .section-collapse-item {
          .title-slot {
            flex: 0 1 80%;
            .section-name-box {
              display: flex;
              flex-direction: row;
              align-items: center;
              .color_block {
                display: flex;
                flex-direction: row;
                align-items: center;
                .color-green {
                  width: 0.06rem;
                  height: 0.19rem;
                  background: #00CBA9;
                  margin-right: 0.21rem;
                }
              }
              .section-name {
                font-size: 0.2rem;
                font-weight: bold;
              }
              .section-tag {
                margin-left: 0.2rem;
              }
            }
            .progress-box {
              padding: 0.1rem 0;
            }
          }
          .child-section-cell {
            .course-name-box {
              .course-name {
                font-size: 0.18rem;
                font-family: Adobe Heiti Std;
                font-weight: normal;
                color: #000000;
              }
              .course-tag {
                margin-left: 0.2rem;
              }
            }
            .course-info {
              margin-top: 0.1rem;
              margin-bottom: 0.1rem;
            }
          }
        }
      }
      .course-teach-tab-con {
        .source-name {
          font-size: 0.18rem;
        }
        .box-card-serve {
          &.box-card-serve-express {
            margin-top: 0.2rem;
          }
          &.box-card-serve-qq {
            margin-top: 0.2rem;
          }
          &.box-card-serve-wx {
            margin-top: 0.2rem;
          }
        }
      }
    }
  }
  .fouter_bot{
    width: 100%;
    height: 160px;
  }
}
</style>
<style lang="less">
#user-course-detail {
  .el-collapse-item__header {
    height: auto;
  }
}
</style>
