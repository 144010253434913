<template>
  <div class="create-order" id="create-order">
    <!-- book 类型商品才需要展示收货信息 -->
    <div :class="adaptive === 'Pc' ? 'index-page' : 'index-page-model'">
      <div class="address-info" v-if="$route.query.type === 'post'">
        <el-card class="box-card">
          <div slot="header" class="clearfix">
            <div class="corlorbox">
              <div class="colorleft"></div>
              <div class="colorfont">收货信息</div>
            </div>
            <div class="rightclearbox">
              <el-button style="padding: 3px;" type="text" @click="editAddrss"
              >编辑地址
              </el-button
              >
              <el-button style="padding: 3px" type="text" @click="addAddrss"
              >新增地址
              </el-button
              >
              <el-button style="padding: 3px" type="text" @click="choiceAddrss"
              >切换地址
              </el-button
              >
            </div>
          </div>
          <div>
          <span style="margin-right:50px;" class="fontsizeAll"
          >收货人：{{
              useAddress && useAddress.receiver ? useAddress.receiver : ""
            }}</span
          >
            <span class="fontsizeAll"
            >电话：{{
                useAddress && useAddress.mobile ? useAddress.mobile : ""
              }}</span
            >
          </div>
          <div style="margin:21px 0;">
          <span class="fontsizeAll"
          >地址：{{
              useAddress
                  ? useAddress.provinceName +
                  useAddress.cityName +
                  useAddress.areaName +
                  useAddress.address
                  : ""
            }}</span
          >
          </div>
          <div>
          <span class="fontsizeAll"
          >发货时间：{{ $route.query.deliveryTime }}</span
          >
          </div>
        </el-card>
      </div>
      <div class="goods-info">
        <el-card class="box-card">
          <div slot="header" class="clearfix">
            <div class="corlorbox">
              <div class="colorleft"></div>
              <div class="colorfont">商品信息</div>
            </div>
          </div>
          <el-table :data="goodsList" style="width: 100%" :show-header="false">
            <el-table-column prop="photo" label="商品图片" width="adaptive === 'Pc' ? '250px' : ''">
              <template slot-scope="scope">
                <el-image
                    v-if="scope.row.logo"
                    :style="adaptive === 'Pc' ? 'width: 233px; height: 150px' : 'width: 2.33rem; height: 1.5rem'"
                    :src="
                  'https://ytk-img.oss-cn-beijing.aliyuncs.com' +
                    scope.row.logo
                "
                    fit="fill"
                ></el-image>
                <el-image
                    v-else
                    :style="adaptive === 'Pc' ? 'width: 233px; height: 150px; font-size: 80px;line-height: 180px;text-align: center;' : 'width: 2.33rem; height: 1.5rem; font-size: 0.8rem;line-height: 1.8rem;text-align: center;'"
                >
                  <div slot="error" class="image-slot">
                    <i class="el-icon-picture-outline"></i>
                  </div>
                </el-image>
              </template>
            </el-table-column>
            <el-table-column prop="info" label="介绍" min-width="adaptive === 'Pc' ? '200px' : ''">
              <template
                  slot-scope="scope"
                  style="height:100%;padding:0;margin:0;"
              >
                <p class="goods-name">{{ scope.row.name }}</p>
                <p class="goods-price">￥ {{ scope.row.labelPrice }}</p>
              </template>
            </el-table-column>
          </el-table>
          <div class="fouter_all">
            <div style="margin-right: 30px;" class="indentMoney">
              订单金额：
              <div class="indent_redMone">￥ {{ finalPrice }}</div>
            </div>
            <el-button
                :style="adaptive === 'Pc' ? 'width:150px;height:40px;font-size:20px;' : 'width:1.5rem;height:0.4rem;font-size:0.2rem;display:flex;align-items: center;justify-content: center;'"
                size="small"
                type="primary"
                @click="goPayPage"
            >确认订单
            </el-button
            >
          </div>
        </el-card>
      </div>
      <!-- 新增/编辑地址 -->
      <el-dialog
          :title="newAddressType"
          :visible.sync="dialogVisible"
          :width="adaptive === 'Pc' ? '600px' : '7rem'"
          :modal-append-to-body='false'
      >
        <div>
          <el-form :model="addressForm" label-width="80px" class="address-form">
            <el-form-item label="收货人">
              <el-input
                  v-model="addressForm.receiver"
                  placeholder="请填写收货人姓名"
                  maxlength="30"
              ></el-input>
            </el-form-item>
            <el-form-item label="电话号码">
              <el-input
                  v-model="addressForm.mobile"
                  maxlength="11"
                  placeholder="请填写手机号"
              ></el-input>
            </el-form-item>
            <el-form-item label="所在地区">
              <el-cascader
                  style="'display:block;'"
                  v-model="areaChoicedList"
                  :options="provinceOptions"
                  :props="props"
                  @change="handleChange"
              ></el-cascader>
            </el-form-item>
            <el-form-item label="详细地址">
              <el-input
                  placeholder="请填写详细地址，街道，小区，门牌号"
                  type="textarea"
                  v-model="addressForm.address"
                  maxlength="100"
              ></el-input>
            </el-form-item>
            <div
                style="display:flex;flex-direction:row;justify-content: flex-start;padding:16px 0 0 10px;align-items: center;"
            >
              <el-switch v-model="addressForm.isFirst"></el-switch>
              <div style="margin-left:10px;color: #000000;font-size: 14px;">设置微默认地址</div>
            </div>
          </el-form>
        </div>
        <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="newAddressSave">确 定</el-button>
      </span>
      </el-dialog>
      <!-- 切换地址 -->
      <el-dialog
          title="选择地址"
          :visible.sync="choiceDialogVisible"
          :width="adaptive === 'Pc' ? '600px' : '7rem'"
          :modal-append-to-body='false'
      >
        <div class="choice-dialog-content">
          <el-card
              class="addrss-card"
              :class="{ 'is-active': item.isActive }"
              v-for="(item, index) in addressList"
              :key="index"
              @click.native="addressClick(item, index)"
          >
            <p>收货人：{{ item.receiver }}</p>
            <p>联系方式：{{ item.mobile }}</p>
            <p>
              详细地址：{{
                item.provinceName + item.cityName + item.areaName + item.address
              }}
            </p>
          </el-card>
        </div>
        <span slot="footer" class="dialog-footer">
        <el-button @click="choiceDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="confirmAddress">确 定</el-button>
      </span>
      </el-dialog>
    </div>
    <fouter class="fouter_bot"></fouter>
  </div>
</template>

<script>
// @ is an alias to /src
// import CourseCard from "@/components/CourseCard.vue";
import {mapState} from "vuex";
import fouter from '../../src/components/fouter/fouter'

export default {
  name: "CreateOrder",
  data() {
    return {
      msg: "CreateOrder  创建订单",
      useAddress: {}, //要使用的地址
      addressList: [],
      newAddressType: "", //新增||编辑 dialog 标题
      dialogVisible: false,
      choiceDialogVisible: false,
      provinceOptions: [], //级联树结构数据
      props: {
        label: "areaName",
        value: "id",
        children: "childAreaList"
      },
      areaChoicedList: [],
      addressForm: {
        id: null,
        userId: null, //从stor 用户信息汇总取得
        receiver: "", //收货人
        mobile: "",
        address: "",
        isFirst: false, //是否默认
        provinceId: null,
        cityId: null,
        areaId: null
      },
      creatOrderBackData: {
        amount: null, //实付价格 <number>
        deliveryTime: "", // 立即发货（购买后3-5天发货） 发货时间 <string>
        requestId: "", //订单编号 <string>
        optionStatus: "n" //y 已支付 n未支付 <string>
      }, //创建订单接口返回值
      //适配类型
      adaptive: ''
    };
  },
  computed: {
    ...mapState([
      // 映射 this.count 为 store.state.count
      "userInfo"
    ]),
    goodsList() {
      return JSON.parse(this.$route.query.goodsList);
    },
    finalPrice() {
      let ref = this;
      let result = 0;
      // console.log("goodsList", ref.goodsList);
      if (ref.goodsList.length === 1) {
        // 单品购买
        result = ref.goodsList[0].labelPrice;
      } else {
        // 联报优惠后价格
        // ref.goodsList.forEach(ele => {
        //   result += ele.labelPrice;
        // });
        result = ref.$route.query.newSumPrice;
      }
      return result;
    }
  },
  methods: {
    goPayPage() {
      // 去往付款页面
      console.log(this.$route.query,'>>>>>>>>>queryyyyyyyyyy')
      let ref = this;
      // let paramData = {
      //   goodsIds: ref.$route.query.goodsIds,
      //   sellType: ref.$route.query.sellType,
      //   addressId: ref.$route.query.type === "book" ? ref.useAddress.id : ""
      // };
      let paramData = {
        goodsId: ref.$route.query.goodsIds,
        userId : ref.$store.state.userInfo.userId,
        reqChanle : '',
        // addressId : ref.$route.query.type === "book" ? ref.useAddress.id : ""
        addressId : ref.$route.query.type === "post" ? ref.useAddress.id : ""
      };
      ref.axios.post("/shop1Api/create/pay/order", paramData).then(res => {
        let data = res.data;
        if (data.success) {
          ref.creatOrderBackData = data.entity;
          if (data.entity.optionStatus === "y") {
            ref.$message({
              type: "success",
              message: "已加入课程中心"
            });
            ref.$router.push({
              path: "/order-center"
            });
          } else {
            ref.$router.push({
              path: "/pay-order",
              query: {
                amount: data.entity.amount,
                requestId: data.entity.requestId
              }
              // type:  course 课程  book 书籍
            });
          }
        }
      });
    },
    handleChange(areaData) {
      // 地区级联结果变更
      console.log("areaData", areaData);
    },
    getAreaOptons(parentId) {
      let ref = this;
      let paramData = {
        parentId
      };
      ref.axios.post("/user1Api/area/query", paramData).then(res => {
        let data = res.data;
        if (res.data.success) {
          data.entity.forEach(eleL1 => {
            eleL1.childAreaList.forEach(eleL2 => {
              if (eleL2.childAreaList.length === 0) {
                delete eleL2.childAreaList;
              }
            });
          });
          ref.provinceOptions = data.entity;
        }
      });
    },
    newAddressSave() {
      // 新增了新的地址或者编辑了现有地址
      let ref = this;
      if (!ref.addressForm.receiver) {
        ref.$message({
          message: "请填写收货人",
          type: "warning"
        });
        return;
      }
      if (!ref.addressForm.mobile) {
        ref.$message({
          message: "请填写电话号码",
          type: "warning"
        });
        return;
      }
      if (!/^1[3456789]\d{9}$/.test(ref.addressForm.mobile)) {
        ref.$message({
          message: "手机号格式错误",
          type: "warning"
        });
        return;
      }
      if (!ref.areaChoicedList.length) {
        ref.$message({
          message: "请选择所在地区",
          type: "warning"
        });
        return;
      }
      let paramData = {
        ...ref.addressForm
      };
      paramData.isFirst = Number(paramData.isFirst);
      paramData.userId = ref.userInfo.userId;
      paramData.provinceId = ref.areaChoicedList[0];
      paramData.cityId = ref.areaChoicedList[1];
      paramData.areaId =
          ref.areaChoicedList.length === 3 ? ref.areaChoicedList[2] : 0;
      if (ref.newAddressType === "新增地址") {
        paramData.id = null;
      }
      // console.log("request paramData", paramData);

      ref.axios.post("/user1Api/modify/user/address", paramData).then(res => {
        let data = res.data;
        if (res.data.success) {
          // 更新前端地址列表
          ref.getAddressList().then(() => {
            ref.useAddress = ref.addressList.find(ele => {
              return ele.id === data.entity.id;
            });
            ref.useAddress.isActive = true;
          });
          // if (ref.newAddressType === "新增地址") {
          // } else if (ref.newAddressType === "编辑地址") {
          // }
        }
      });
      ref.dialogVisible = false;
    },
    confirmAddress() {
      // 确认切换地址
      let ref = this;
      ref.useAddress = ref.addressList.find(ele => {
        return ele.isActive;
      });
      ref.choiceDialogVisible = false;
    },
    addressClick(item, index) {
      let ref = this;
      ref.addressList.forEach((ele, i) => {
        if (i === index) {
          ele.isActive = true;
        } else {
          ele.isActive = false;
        }
      });
    },
    choiceAddrss() {
      // 切换地址
      this.choiceDialogVisible = true;
    },
    editAddrss() {
      // 编辑当前地址
      let ref = this;
      let {
        id,
        userId, //从stor 用户信息汇总取得
        receiver, //收货人
        mobile,
        address,
        isFirst, //是否默认
        provinceId,
        cityId,
        areaId
      } = {...ref.useAddress};
      ref.addressForm = {
        id,
        userId, //从stor 用户信息汇总取得
        receiver, //收货人
        mobile,
        address,
        isFirst, //是否默认
        provinceId,
        cityId,
        areaId
      };
      // 是否默认格式转换
      ref.addressForm.isFirst = !!ref.addressForm.isFirst;
      // 构建选择区域级联数据
      ref.areaChoicedList = [
        ref.addressForm.provinceId,
        ref.addressForm.cityId
      ];
      if (ref.addressForm.areaId) {
        ref.areaChoicedList.push(ref.addressForm.areaId);
      }
      ref.newAddressType = "编辑地址";
      this.dialogVisible = true;
    },
    addAddrss() {
      // 新增地址
      let ref = this;
      ref.addressForm = {
        id: null,
        userId: null, //从stor 用户信息汇总取得
        receiver: "", //收货人
        mobile: "",
        address: "",
        isFirst: false, //是否默认
        provinceId: null,
        cityId: null,
        areaId: null
      };
      // 是否默认格式转换
      ref.addressForm.isFirst = !!ref.addressForm.isFirst;
      // 构建选择区域级联数据——新增时置为空
      ref.areaChoicedList = [];
      ref.newAddressType = "新增地址";
      this.dialogVisible = true;
    },
    getDefaultAddress() {
      // 获取当前分类的课程列表
      let ref = this;
      let paramData = {
        userId : ref.$store.state.userInfo.userId
      };
      ref.axios.post("/user1Api/user/address", paramData).then(res => {
        let data = res.data;
        if (res.data.success) {
          ref.useAddress = data.entity;
        }
      });
    },
    getAddressList() {
      // 获取当前分类的课程列表
      let ref = this;
      let paramData = {
        userId : ref.$store.state.userInfo.userId
      };
      return ref.axios
          .post("/user1Api/user/address/list", paramData)
          .then(res => {
            let data = res.data;
            if (res.data.success) {
              data.entity.forEach(element => {
                element.isActive = false;
              });
              ref.addressList = data.entity;
            }
          });
    }
  },
  mounted() {
    this.adaptive = this.$store.state.adaptive
    let ref = this;
    console.log('00000000')
    // 如果当前用户未登录，则不发起下列请求
    let app_keyword = 'west'
    // let userId = ''
    let userId = ref.$store.state.userInfo.userId
    ref.axios.post("/user1Api/user/info", {app_keyword,userId}).then(res => {
      let data = res.data;
      if (data.success) {
        ref.getAreaOptons();
        // if (ref.$route.query.type === "book") {
        if (ref.$route.query.type === "post") {
          ref.getDefaultAddress();
          ref.getAddressList();
        }
      } else {
        ref.$router.go(-1);
      }
    });
  },
  components: {
    // CourseCard
    fouter
  }
};
</script>
<style lang="less" scoped>
.create-order {
  padding-top: 70px;
  text-align: left;

  .index-page {
    width: 1200px;
    margin: 0 auto;
    min-height: calc(100vh - 160px);

    .address-info {
      font-weight: normal;

      .clearfix {
        display: flex;
        flex-direction: row;

        .corlorbox {
          display: flex;
          flex-direction: row;
          width: 20%;

          .colorleft {
            width: 6px;
            height: 20px;
            background: #2065ff;
            margin-right: 12px;
          }

          .colorfont {
            font-size: 16px;
            font-family: Microsoft YaHei;
            font-weight: bold;
            color: #000000;
          }
        }

        .rightclearbox {
          width: 80%;
          display: flex;
          justify-content: flex-end;
          align-items: center;
        }
      }

      .fontsizeAll {
        font-size: 14px;
        font-family: Hiragino Sans GB;
        font-weight: normal;
        color: #000000;
      }
    }

    .goods-info {
      margin-top: 20px;

      .corlorbox {
        display: flex;
        flex-direction: row;

        .colorleft {
          width: 6px;
          height: 20px;
          background: #2065ff;
          margin-right: 12px;
        }

        .colorfont {
          font-size: 16px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          color: #000000;
        }
      }

      .goods-name {
        font-size: 22px;
        font-family: Adobe Heiti Std;
        font-weight: normal;
        color: #000000;
        margin: 0;
        margin-bottom: 35px;
      }

      .goods-price {
        // width: 48px;
        font-size: 18px;
        font-family: Hiragino Sans GB;
        font-weight: normal;
        color: #000000;
      }

      .fouter_all {
        // text-align: right;
        padding-top: 20px;
        display: flex;
        align-items: center;
        justify-content: flex-end;

        .indentMoney {
          font-size: 18px;
          font-family: Hiragino Sans GB;
          font-weight: normal;
          color: #000000;
          display: flex;
          align-items: center;

          .indent_redMone {
            font-size: 24px;
            font-family: Hiragino Sans GB;
            font-weight: normal;
            color: #fb2626;
          }
        }
      }
    }

    .choice-dialog-content {
      .addrss-card {
        cursor: pointer;
        margin-bottom: 10px;

        &.is-active {
          border-color: #00cba9;
          border-width: 2px;
        }
      }
    }
  }

  .index-page-model {
    width: 100%;
    margin: 0 auto;
    min-height: calc(100vh - 160px);

    .address-info {
      font-weight: normal;

      .clearfix {
        display: flex;
        flex-direction: row;

        .corlorbox {
          display: flex;
          flex-direction: row;
          width: 20%;

          .colorleft {
            width: 0.06rem;
            height: 0.2rem;
            background: #2065ff;
            margin-right: 0.12rem;
          }

          .colorfont {
            font-size: 0.16rem;
            font-family: Microsoft YaHei;
            font-weight: bold;
            color: #000000;
          }
        }

        .rightclearbox {
          width: 80%;
          display: flex;
          justify-content: flex-end;
          align-items: center;
        }
      }

      .fontsizeAll {
        font-size: 0.14rem;
        font-family: Hiragino Sans GB;
        font-weight: normal;
        color: #000000;
      }
    }

    .goods-info {
      margin-top: 0.2rem;

      .corlorbox {
        display: flex;
        flex-direction: row;

        .colorleft {
          width: 0.06rem;
          height: 0.2rem;
          background: #2065ff;
          margin-right: 0.12rem;
        }

        .colorfont {
          font-size: 0.16rem;
          font-family: Microsoft YaHei;
          font-weight: bold;
          color: #000000;
        }
      }

      .goods-name {
        font-size: 0.22rem;
        font-family: Adobe Heiti Std;
        font-weight: normal;
        color: #000000;
        margin: 0;
        margin-bottom: 0.35rem;
      }

      .goods-price {
        font-size: 0.18rem;
        font-family: Hiragino Sans GB;
        font-weight: normal;
        color: #000000;
      }

      .fouter_all {
        // text-align: right;
        padding-top: 0.2rem;
        display: flex;
        align-items: center;
        justify-content: flex-end;

        .indentMoney {
          font-size: 0.18rem;
          font-family: Hiragino Sans GB;
          font-weight: normal;
          color: #000000;
          display: flex;
          align-items: center;

          .indent_redMone {
            font-size: 0.24rem;
            font-family: Hiragino Sans GB;
            font-weight: normal;
            color: #fb2626;
          }
        }
      }
    }

    .choice-dialog-content {
      .addrss-card {
        cursor: pointer;
        margin-bottom: 0.1rem;

        &.is-active {
          border-color: #00cba9;
          border-width: 2px;
        }
      }
    }
  }

  .fouter_bot {
    width: 100%;
    height: 160px;
  }
}
</style>
<style>
.el-dialog__title {
  font-weight: bold;
}
/*
*多级联动城市选择样式 pc兼容移动端
 */
.el-popper{
  position: fixed;
  left: 0;
}
.el-cascader__dropdown{
  position: fixed;
  left: 0;
}
.el-scrollbar{
  min-width: 0 !important;
}
.el-cascader-menu{
  min-width: 0 !important;
}
</style>
