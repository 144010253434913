<template>
  <div class="sign-up" id="sign-up">
    <!-- <img alt="Vue logo" src="../assets/logo.png" /> -->

    <div v-if="showModal" class="mask" @click="closePop"></div>

    <div :class="adaptive === 'Pc' ? 'Box' : 'Box-model'">
      <div class="header">
        <img
          src="https://ytk-front.oss-cn-beijing.aliyuncs.com/pc/doctorTopic/header/logo.png"
          class="icon"
          alt=""
        />
<!--        <div class="header_font">-->
<!--          <div class="hefont_tit">研大考研</div>-->
<!--          <div class="hefont_bot">考研名师直播课</div>-->
<!--        </div>-->
      </div>
      <div class="phoneNum">
        <el-input
          placeholder="手机号"
          prefix-icon="el-icon-phone"
          v-model="form.mobile"
          maxlength="11"
        >
        </el-input>
      </div>
      <div class="authCode">
        <el-input
          placeholder="请输入验证码"
          prefix-icon="el-icon-message"
          v-model="form.code"
          maxlength="6"
        >
          <el-button :disabled="isgetCodeDisable" slot="append" @click="getCode"
            >获取验证码</el-button
          >
        </el-input>
      </div>
      <div class="possword">
        <el-input
          placeholder="输入新的密码"
          prefix-icon="el-icon-lock"
          v-model="form.newPwd"
          maxlength="20"
          show-password
        >
        </el-input>
      </div>
      <div class="possword">
        <el-input
          placeholder="重复输入新的密码"
          prefix-icon="el-icon-lock"
          v-model="twopassword"
          maxlength="20"
          show-password
        >
        </el-input>
      </div>
      <el-button type="primary" class="confirm" @click="signUp"
        >重置密码</el-button
      >
    </div>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: "SignIn",
  data() {
    return {
      form: {
        mobile: "",
        code: "",
        newPwd: ""
      },
      twopassword: "",
      isgetCodeDisable: false,
      timer: null,
      showModal: true,
      //适配类型
      adaptive : ''
    };
  },
  methods: {
    closePop(){
      this.$store.commit("signgorgetPass", 1);
    },
    signUp() {
      if (this.form.newPwd === this.twopassword && this.form.newPwd) {
        console.log("...");
      } else {
        this.$message({
          message: "密码输入不一致",
          type: "warning"
        });
        return;
      }
      let ref = this;
      if (!ref.form.mobile) {
        ref.$message({
          message: "请填写您的手机号",
          type: "warning"
        });
        return false;
      }
      if (!ref.form.code) {
        ref.$message({
          message: "请填写验证码",
          type: "warning"
        });
        return false;
      }
      if (!ref.form.newPwd) {
        ref.$message({
          message: "请为您的帐号设置密码",
          type: "warning"
        });
        return false;
      }
      if (!ref.twopassword) {
        ref.$message({
          message: "请为您的帐号设置密码",
          type: "warning"
        });
        return false;
      }

      ref.axios.post("/user1Api/mobile/update/pwd", ref.form).then(res => {
        if (res.data.success) {
          // 重定向至登陆页面
          ref.$store.commit("signUpAppType", 1);
          ref.$message({
            message: "注册成功,请登录您的帐号",
            type: "success"
          });
          ref.$store.commit("signgorgetPass", 1);
          ref.$store.commit(
            "signinAppType",
            (this.$store.state.signinType += 1)
          );
          // ref.$router.push({ path: "/sign-in" });
        } else {
          ref.$message({
            message: res.data.message,
            type: "warning"
          });
        }
      });
    },
    getCode() {
      // 调用接口发送短信
      let ref = this;
      if (!ref.form.mobile) {
        ref.$message({
          message: "请填写手机号",
          type: "warning"
        });
        return false;
      }
      let paramData = {
        mobile: ref.form.mobile,
        type: "forget"
      };
      ref.axios.post("/main1Api/mobile/code", paramData).then(res => {
        if (res.data.success) {
          ref.$message({
            message: "已发送请注意查收,60s后可从重新发送",
            type: "success"
          });
          ref.isgetCodeDisable = true;
          clearTimeout(ref.timer);
          ref.timer = setTimeout(() => {
            ref.isgetCodeDisable = false;
          }, 6 * 1000);
        } else {
          ref.$message({
            message: res.data.message,
            type: "warning"
          });
        }
      });
    },
    loginIn() {
      this.$store.commit("signinAppType", (this.$store.state.signinType += 1));
    }
  },
  components: {},
  mounted() {
    this.adaptive = this.$store.state.adaptive
  }
};
</script>
<style lang="less" scoped>
.sign-up {
  height: 100%;
  display: flex;
  // flex-direction: column;
  justify-content: center;
  align-items: center;
  .mask {
    background-color: rgb(0, 0, 0);
    opacity: 0.3;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 99;
  }
  .Box {
    width: 500px;
    height: 540px;
    background: #ffffff;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 101;
    .header {
      margin-top: 48px;
      display: flex;
      flex-direction: row;
      align-items: center;
      .icon {
        width:144px;
        height: 50px;
      }
      .header_font {
        display: flex;
        flex-direction: column;
        margin-left: 14px;
        display: flex;
        align-items: flex-start;
        .hefont_tit {
          font-size: 24px;
          font-family: ZhenyanGB;
          font-weight: bold;
          color: #000000;
        }
        .hefont_bot {
          font-size: 17px;
          font-family: Hiragino Sans GB;
          font-weight: normal;
          color: #000000;
          margin-top: 8px;
        }
      }
    }
    .phoneNum {
      margin-top: 42px;
      width: 433px;
      background: #ffffff;
      border: 1px solid #d4d4d4;
      border-radius: 4px;
    }
    .authCode {
      margin-top: 25px;
      width: 433px;
      background: #ffffff;
      border: 1px solid #d4d4d4;
      border-radius: 4px;
    }
    .possword {
      margin-top: 25px;
      width: 433px;
      background: #ffffff;
      border: 1px solid #d4d4d4;
      border-radius: 4px;
    }
    .confirm {
      width: 433px;
      height: 50px;
      background: #2065ff;
      border: 0;
      border-radius: 4px;
      font-size: 20px;
      font-family: Hiragino Sans GB;
      font-weight: normal;
      color: #ffffff;
      margin-top: 25px;
    }
    .register {
      font-size: 16px;
      font-family: Hiragino Sans GB;
      font-weight: normal;
      text-decoration: underline;
      color: #707070;
      margin-top: 22px;
      cursor: pointer;
    }
    .protocol {
      font-size: 16px;
      font-family: Hiragino Sans GB;
      font-weight: normal;
      color: #707070;
      margin-top: 35px;
    }
  }
  .Box-model {
    width: 6rem;
    height: 7.2rem;
    background: #ffffff;
    border-radius: 0.1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 101;
    .header {
      margin-top: 0.48rem;
      display: flex;
      flex-direction: row;
      align-items: center;
      .icon {
        width: 1.6rem;
        height: 0.5rem;
      }
      .header_font {
        display: flex;
        flex-direction: column;
        margin-left: 0.14rem;
        display: flex;
        align-items: flex-start;
        .hefont_tit {
          font-size: 0.24rem;
          font-family: ZhenyanGB;
          font-weight: bold;
          color: #000000;
        }
        .hefont_bot {
          font-size: 0.17rem;
          font-family: Hiragino Sans GB;
          font-weight: normal;
          color: #000000;
          margin-top: 0.08rem;
        }
      }
    }
    .phoneNum {
      margin-top: 0.42rem;
      width: 4.33rem;
      background: #ffffff;
      border: 1px solid #d4d4d4;
      border-radius: 0.04rem;
    }
    .authCode {
      margin-top: 0.25rem;
      width: 4.33rem;
      background: #ffffff;
      border: 1px solid #d4d4d4;
      border-radius: 0.04rem;
    }
    .possword {
      margin-top: 0.25rem;
      width: 4.33rem;
      background: #ffffff;
      border: 1px solid #d4d4d4;
      border-radius: 0.04rem;
    }
    .confirm {
      width: 4.33rem;
      height: 0.8rem;
      background: #2065ff;
      border: 0;
      border-radius: 4px;
      font-size: 0.2rem;
      font-family: Hiragino Sans GB;
      font-weight: normal;
      color: #ffffff;
      margin-top: 0.25rem;
    }
    .register {
      font-size: 0.16rem;
      font-family: Hiragino Sans GB;
      font-weight: normal;
      text-decoration: underline;
      color: #707070;
      margin-top: 0.22rem;
      cursor: pointer;
    }
    .protocol {
      font-size: 0.16rem;
      font-family: Hiragino Sans GB;
      font-weight: normal;
      color: #707070;
      margin-top: 0.35rem;
    }
  }
  .sign-up-box {
    width: 400px;
    padding: 40px 20px 10px;
    background: #fff;
    border-radius: 6px;
  }
}
</style>
