<template>
  <div class="comment-page" id="comment-page">
    <div class="comment-box">
      <div class="section-info">
        {{ queryData.courseName + "——" + queryData.name }}
        <el-button
          style="float:right;"
          type="primary"
          size="small"
          @click="back"
          >返回</el-button
        >
      </div>
      <el-table :data="commentList" style="width: 100%" :show-header="false">
        <el-table-column prop="photo" label="头像" width="140px">
          <template slot-scope="scope">
            <el-image
              style="width: 100px; height: 100px"
              :src="
                'https://yd-kc-img.oss-cn-beijing.aliyuncs.com' +
                  scope.row.avatar
              "
              fit="fill"
            ></el-image>
          </template>
        </el-table-column>
        <el-table-column prop="handler" label="介绍" min-width="200px">
          <template slot-scope="scope">
            <p class="student-name-time">
              <span class="student-name">{{ scope.row.username }}</span>
              <span class="comment-time">{{ scope.row.createTimeStr }}</span>
            </p>
            <p class="comment-info">{{ scope.row.content }}</p>
          </template>
        </el-table-column>
      </el-table>
      <div style="text-align:center; padding-top:20px;" v-show="!noMoreComment">
        <el-button type="text" @click="loadMoreComment">加载更多...</el-button>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
export default {
  name: "CommentPage",
  data() {
    return {
      msg: "CommentPage  评论页",
      commentList: [],
      queryData: {},
      noMoreComment: false,
      page: {
        currentPage: 1,
        pageSize: 10,
        total: 0
      }
    };
  },
  methods: {
    back() {
      let ref = this;
      ref.$router.go(-1);
    },
    loadMoreComment() {
      this.page.currentPage++;
      this.getComments(true);
    },
    getComments(isMore) {
      let ref = this;
      console.log(ref.$route.query,'>>>>111')
      let paramData = {
        type: "section",
        otherId: ref.$route.query.otherId,
        "page.currentPage": ref.page.currentPage,
        "page.pageSize": ref.page.pageSize,
        userId : ref.$store.state.userInfo.userId
      };
      ref.axios.post("/user1Api/new/comment/list", paramData).then(res => {
        let data = res.data;
        if (res.data.success) {
          // data
          if (isMore && !data.entity.commentList.length) {
            ref.$message({
              message: "没有更多评论数据了~。~",
              type: "warning"
            });
            ref.noMoreComment = true;
          } else {
            ref.commentList = ref.commentList.concat(data.entity.commentList);
          }
        }
      });
    }
  },
  created() {
    this.queryData = this.$route.query;
    this.getComments();
  }
};
</script>
<style lang="less" scoped>
#comment-page {
  .comment-box {
    width: 1200px;
    margin: 0 auto;
    padding-top: 70px;
    .section-info {
      text-align: left;
      margin-bottom: 10px;
      line-height: 32px;
    }
    .student-name-time {
      .student-name {
        font-size: 16px;
        font-weight: bold;
      }
      .comment-time {
        float: right;
      }
    }
  }
}
</style>
