import Vue from "vue";
import VueRouter from "vue-router";
import SignIn from "../views/SignIn.vue";
import SignUp from "../views/SignUp.vue";
import Index from "../views/Index.vue";
import CourseDetailBoundle from "../views/CourseDetailBoundle.vue";
import CourseDetailSingle from "../views/CourseDetailSingle.vue";
import CreateOrder from "../views/CreateOrder.vue";
import PayOrder from "../views/PayOrder.vue";
import OrderCenter from "../views/OrderCenter.vue";
import UserCourseCenter from "../views/UserCourseCenter.vue";
import UserCourseDetail from "../views/UserCourseDetail.vue";
import VideoPage from "../views/VideoPage.vue";
import LivePage from "../views/LivePage.vue";
import CommentPage from "../views/CommentPage.vue";
//百家云点播视频video
import onDemandVideo from "@/views/OnDemandVideo/onDemandVideo";

Vue.use(VueRouter);

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location) {
  return originalPush.call(this, location).catch(err => err)
}

const routes = [
  {
    path: "/",
    // name: "Index",
    // component: Index,
    redirect:'/index'
  },
  {
    path: "/sign-in",
    name: "SignIn",
    component: SignIn
  },
  {
    path: "/sign-up",
    name: "SignUp",
    component: SignUp
  },
  {
    path: "/index",
    name: "Index",
    component: Index
  },
  {
    path: "/course-detail-boundle",
    name: "CourseDetailBoundle",
    component: CourseDetailBoundle
  },
  {
    path: "/course-detail-single",
    name: "CourseDetailSingle",
    component: CourseDetailSingle
  },
  {
    path: "/onDemandVideo",
    name: "onDemandVideo",
    component: onDemandVideo
  },
  {
    path: "/create-order",
    name: "CreateOrder",
    component: CreateOrder
  },
  {
    path: "/pay-order",
    name: "PayOrder",
    component: PayOrder
  },
  {
    path: "/order-center",
    name: "OrderCenter",
    component: OrderCenter
  },
  {
    path: "/user-course-center",
    name: "UserCourseCenter",
    component: UserCourseCenter
  },
  {
    path: "/user-course-detail",
    name: "UserCourseDetail",
    component: UserCourseDetail
  },
  {
    path: "/video-page",
    name: "VideoPage",
    component: VideoPage
  },
  {
    path: "/live-page",
    name: "LivePage",
    component: LivePage
  },
  {
    path: "/comment-page",
    name: "CommentPage",
    component: CommentPage
  },
  {
    path: "/about",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue")
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

export default router;
