import Vue from "vue";
import Vuex from "vuex";
import persistedState from "vuex-persistedstate";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    isLogin: false,
    navList: [],
    currentNavIndex: 0,
    userInfo: {
      userId: null
    },
    //点击登录全局判断显示登录窗口
    signinType: 1,
    //点击注册
    signUpType: 1,
    //忘记密码
    signforget: 1,
    //clear原先signin的query参数
    querylcer : 0,
    //验证设备类型
    adaptive : 'Pc',

    currentNav: {
      idStr : 1,
      type : 0,
      goodsType: ""
    },
  },
  mutations: {
    setNavList(state, list) {
      state.navList = list;
    },
    setLoginState(state, isLogin) {
      state.isLogin = isLogin;
    },
    setCurrentNavIndex(state, index) {
      state.currentNavIndex = index;
    },
    setCurrentNavIndexId(state, index) {
      state.currentNav.idStr = index;
    },
    setCurrentNavIndexType(state, index) {
      // state.currentNavIndex = index;
      state.currentNav.type = index;
    },
    setCurrentNavGoodType(state, index) {
      state.currentNav.goodsType = index;
    },
    setUserInfo(state, userData) {
      state.userInfo = userData;
    },
    signinAppType(state, signinType) {
      state.signinType = signinType
    },
    signUpAppType(state, signUpType) {
      state.signUpType = signUpType
    },
    signgorgetPass(state,signforget) {
      state.signforget = signforget
    },
    signQueryClear(state,querylcer) {
      state.querylcer = querylcer
    },
    signAdaptive(state,querylcer) {
      state.adaptive = querylcer
    }
    // setCurrentNav(state, navItem) {
    //   state.currentNav = navItem;
    // }
  },
  getters: {
    currentNav: state => {
      // return state.navList[state.currentNavIndex];
      // return state.currentNavIndex;
      return state.currentNav;
    },
    currentBanners: state => {
      /**
       * 21yan的数据结构是state.navList[state.currentNavIndex].activityList
       * 163新反的数据结构不一致
       */
      // return state.navList.length ? state.navList[state.currentNavIndex].activityList : [];
      return state.navList.length ? state.navList : [];
    }
  },
  actions: {},
  modules: {},
  plugins: [
    // persistedState({
    //   storage: window.sessionStorage
    // })
    // 为保持状态，使用localsTorage
    persistedState()
  ]
});
