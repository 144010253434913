<template>
  <div class="user-course-center" id="user-course-center">
    <div :class="adaptive === 'Pc' ? 'index-page-box' : 'index-page-box-model'">
      <!-- <div class="today-course">
        <el-card class="box-card">
          <div slot="header" class="clearfix">
            <span>今日课程</span>
          </div>
          <el-table
              :data="todayCourseConverted"
              style="width: 100%"
              :show-header="false"
          >
            <el-table-column prop="time" label="时间" width="100px">
              <template slot-scope="scope">
                <el-tag class="time-tag" effect="dark">{{
                    scope.row.startTimeStr
                  }}
                </el-tag>
              </template>
            </el-table-column>
            <el-table-column prop="name" label="课程名称" min-width="100px">
              <template slot-scope="scope">
                <div class="today-cell">
                  <p class="course-name">{{ scope.row.name }}</p>
                  <p class="course-info">
                  <span>
                    <el-tag size="mini">
                      {{ getSectionType(scope.row) }}
                    </el-tag>
                  </span>
                    <span style="margin:0 10px;">|</span>
                    <span>{{ scope.row.teacherName }}</span>
                  </p>
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="handler" label="操作" width="100px">
              <template slot-scope="scope">
                <el-button size="small" type="primary" @click="goVideo(scope.row)"
                >开始学习
                </el-button
                >
              </template>
            </el-table-column>
          </el-table>
        </el-card>
      </div> -->
      <div class="user-course">
        <el-card class="box-card">
          <div slot="header" class="clearfix">
            <span>我的课程</span>
          </div>
          <el-table :data="courseList" v-loading="tableLoad" style="width: 100%" :show-header="false">
            <el-table-column prop="name" label="课程名称" min-width="200px">
              <template slot-scope="scope">
                <div class="course-cell">
                  <p class="course-name-p">
                  <span>
                    <el-tag size="mini" class="time-tag" effect="dark">{{
                        scope.row.professionName
                      }}</el-tag>
                  </span>
                    <span class="course-name-span">{{ scope.row.name }}</span>
                  </p>
                  <div class="progress-box">
                    <el-progress
                        :percentage="getProgress(scope.row)"
                    ></el-progress>
                    <p style="margin-top:16px">
                    <span
                        style="font-size: 14px;font-family: Adobe Heiti Std;font-weight: normal;color: #676767;"
                    >已学习{{ scope.row.studyHour }} /
                      {{ scope.row.classHour }}</span
                    >
                      <span class="lose-time" v-if="!!scope.row.showTime">{{
                          scope.row.loseTime
                        }}</span>
                    </p>
                  </div>
                  <div class="teacher-box">
                    <!--                  <el-card-->
                    <!--                    class="teacher-card"-->
                    <!--                    v-for="(teacher, index) in scope.row.teacherList"-->
                    <!--                    :key="index"-->
                    <!--                  >-->
                    <div class="teacher-card"
                         v-for="(teacher, index) in scope.row.teacherList"
                         :key="index">

                      <img
                          :src="
                        'https://ytk-img.oss-cn-beijing.aliyuncs.com' +
                          teacher.photo
                      "
                          class="teacher-img"
                      />
                      <div class="teacher-name">
                        <span>{{ teacher.name }}</span>
                      </div>
                    </div>
                    <!--                  </el-card>-->
                  </div>
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="handler" label="操作" width="100px">
              <template slot-scope="scope">
                <el-button
                    size="small"
                    type="primary"
                    @click="goUserCourseDetail(scope.row)"
                >开始学习
                </el-button
                >
              </template>
            </el-table-column>
          </el-table>
        </el-card>
      </div>
    </div>
    <fouter class="fouter_bot"></fouter>
  </div>
</template>

<script>
// @ is an alias to /src
// import CourseCard from "@/components/CourseCard.vue";
import fouter from '../../src/components/fouter/fouter'
export default {
  // name: "UserCourseCenter",
  data() {
    return {
      msg: "UserCourseCenter  我的课程",
      courseList: [], //课程列表
      todayCourse: [], //今日课程
      typeEnum: {
        "1": "文件",
        "2": "录播",
        "3": "直播"
      },
      liveStatusEnum: {
        start: "未开始",
        on: "正在直播",
        end: "已结束",
        back: "回放"
      },
      //适配类型
      adaptive: '',
      //loading
      tableLoad : true
    };
  },
  computed: {
    todayCourseConverted() {
      let ref = this;
      let result = [];
      ref.todayCourse.forEach(ele => {
        result = result.concat(ele.sectionModelList);
      });
      return result;
    }
  },
  methods: {
    getSectionType(row) {
      let ref = this;
      let type = "";
      if (row.type === 3) {
        type = ref.liveStatusEnum[row.liveStatus];
      } else {
        type = ref.typeEnum[row.type];
      }

      return type;
    },
    getProgress(row) {
      let persont = 0;
      if (!row.classHour || !row.studyHour) {
        persont = 0;
      } else {
        persont = +((row.studyHour / row.classHour) * 100).toFixed(1);
      }
      return persont;
    },
    goUserCourseDetail(item) {
      let ref = this;
      // 跳转至课程详情
      // console.log("go user course detail" + item.id);
      ref.$router.push({
        path: "/user-course-detail",
        query: {courseId: item.id}
      });
    },
    goRecordedBroadcast(item, data) {
      let ref = this;
      ref.$router.push({
        path: "/video-page",
        query: {
          sectionId: item.IdStr,
          sectionName: item.name,
          v: data.entity.sectionInfo.vid,
          c: data.entity.sectionInfo.code,
          s: data.entity.sectionInfo.playSafe,
          ts: data.entity.sectionInfo.ts,
          sign: data.entity.sectionInfo.sign
        }
      });
    },
    goVideo(item) {
      let ref = this;
      // id已经在请求接口时暴露无需base64混淆处理
      // let salt = ref.rondomPass(5);
      // 跳转至课程详情
      // console.log("item.id", item);
      let paramData = {
        sectionId: item.IdStr
      };
      ref.axios.post("/shop1Api/check/course/section", paramData).then(res => {
        let data = res.data;
        if (data.success) {
          if (data.entity.isOk) {
            // 根据sectionInfo 判断是直播还是点播
            if (data.entity.sectionInfo) {
              // 录播
              ref.goRecordedBroadcast(item, data);
            } else {
              window.open(data.entity.liveUrl, "_blank");
            }
          } else {
            // 未购买
            ref.$message({
              message: "请先购买课程",
              type: "warning"
            });
          }
        } else {
          // 接口异常
          ref.$message({
            message: data.message,
            type: "warning"
          });
        }
      });
    },
    goVideoBak(item) {
      let ref = this;
      // id已经在请求接口时暴露无需base64混淆处理
      // let salt = ref.rondomPass(5);
      // 跳转至课程详情
      // console.log("item.id", item);
      let paramData = {
        sectionId: item.IdStr
      };
      ref.axios.post("/shop1Api/check/course/section", paramData).then(res => {
        let data = res.data;
        if (data.success) {
          if (data.entity.isOk) {
            // 已购买 (type 2 录播 3直播)
            if (data.entity.type === 2) {
              // 录播
              ref.goRecordedBroadcast(item, data);
            } else if (data.entity.type === 3) {
              // 直播
              // "liveStatus": "start"直播状态 start 未开始 on 正在直播 end 已结束 back 已结束有回放
              if (data.entity.liveStatus === "start") {
                ref.$message({
                  message: `直播尚未开始，开始时间: ${item.startTime}`,
                  type: "warning"
                });
                return false;
              } else if (data.entity.liveStatus === "end") {
                ref.$message({
                  message: `直播已结束，暂无回放视频`,
                  type: "warning"
                });
                return false;
              } else if (data.entity.liveStatus === "on") {
                ref.$router.push({
                  path: "/live-page",
                  query: {
                    channelId: data.entity.sectionInfo.channelId,
                    sign: data.entity.sectionInfo.sign,
                    timestamp: data.entity.sectionInfo.timestamp,
                    appId: data.entity.sectionInfo.appId,
                    userId: data.entity.user.userId,
                    userName: data.entity.user.userName,
                    pic:
                        "https://yd-kc-img.oss-cn-beijing.aliyuncs.com" +
                        data.entity.user.pic,
                    roomId: data.entity.sectionInfo.channelId,
                    nick: data.entity.user.userName,
                    token: data.entity.sectionInfo.token,
                    mediaChannelKey: data.entity.sectionInfo.mediaChannelKey,
                    scene: data.entity.sectionInfo.scene,
                    sectionName: item.name
                  }
                });
              } else if (data.entity.liveStatus === "back") {
                // 直播结束可观看录播
                ref.goRecordedBroadcast(item, data);
              }
            } else {
              console.warning("未知媒体类型");
            }
          } else {
            // 未购买
            ref.$message({
              message: "请先购买课程",
              type: "warning"
            });
          }
        } else {
          // 接口异常
          ref.$message({
            message: data.message,
            type: "warning"
          });
        }
      });
    },
    getCourseList() {
      // 获取当前分类的课程列表
      let ref = this;
      // let paramData = {};
      let paramData = {
        professionId : ref.$store.state.currentNav.idStr,
        userId : ref.$store.state.userInfo.userId
      };
      ref.axios.post("/shop1Api/course/today", paramData).then(res => {
        let data = res.data;
        if (res.data.success) {
          // data
          ref.courseList = data.entity.courseList;
          // ref.todayCourse = data.entity.todayCourse;
        }
        this.tableLoad = false
      })
    }
  },
  created() {
    this.adaptive = this.$store.state.adaptive
    this.getCourseList();
  },
  components: {
    // CourseCard
    fouter
  }
};
</script>
<style lang="less" scoped>
html {
  height: 100%;
}
.user-course-center {
  padding-top: 70px;
  min-height: calc(100vh - 160px);
  p {
    margin: 0;
    padding: 0;
  }
  .index-page-box{
    width: 1200px;
    margin: 0 auto;
    .today-course {
      background-color: #fff;

      .box-card {
        .clearfix {
          display: flex;
          font-size: 18px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          color: #000000;
          line-height: 42px;
        }
      }

      // 今日课程
      .time-tag {
        padding: 0 30px;
        position: relative;

        &::after {
          content: "";
          position: absolute;
          display: block;
          width: 0px;
          height: 0px;
          border-right: 10px solid transparent;
          border-bottom: 10px solid transparent;
          border-left: 10px solid #00cba9;
          border-top: 10px solid transparent;
          right: -20px;
          top: 6px;
        }
      }

      .today-cell {
        .course-name {
          font-size: 18px;
        }

        .course-info {
          margin-top: 10px;
          margin-bottom: 10px;
        }
      }
    }

    .user-course {
      // 我的课程
      background-color: #fff;
      margin-top: 20px;

      .box-card {
        .clearfix {
          display: flex;
          font-size: 18px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          color: #000000;
          line-height: 42px;
        }
      }

      .course-cell {
        .course-name-p {
          display: flex;

          .course-name-span {
            margin-left: 13px;
            font-size: 18px;
            font-family: Adobe Heiti Std;
            font-weight: normal;
            color: #000000;
          }
        }

        .progress-box {
          padding: 10px 0;

          .lose-time {
            margin-left: 50px;
          }
        }

        .teacher-box {
          padding: 10px 0;
          display: flex;
          flex-wrap: wrap;

          .teacher-card {
            width: 100px;
            height: 120px;
            margin-right: 5px;
            margin-bottom: 5px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            .teacher-img {
              width: 50px;
              height: 50px;
              border-radius: 50%;
            }

            .teacher-name {
              text-align: center;
              font-size: 12px;
              font-family: Hiragino Sans GB;
              font-weight: normal;
              color: #323232;
              margin-top: 9px;
            }
          }
        }
      }
    }
  }
  .index-page-box-model{
    width: 100%;
    margin: 0 auto;
    .today-course {
      background-color: #fff;

      .box-card {
        .clearfix {
          display: flex;
          font-size: 0.18rem;
          font-family: Microsoft YaHei;
          font-weight: bold;
          color: #000000;
          line-height: 0.42rem;
        }
      }

      // 今日课程
      .time-tag {
        padding: 0 0.3rem;
        position: relative;

        &::after {
          content: "";
          position: absolute;
          display: block;
          width: 0;
          height: 0;
          border-right: 0.1rem solid transparent;
          border-bottom: 0.1rem solid transparent;
          border-left: 0.1rem solid #00cba9;
          border-top: 0.1rem solid transparent;
          right: -0.2rem;
          top: 0.06rem;
        }
      }

      .today-cell {
        .course-name {
          font-size: 0.18rem;
        }

        .course-info {
          margin-top: 0.1rem;
          margin-bottom: 0.1rem;
        }
      }
    }

    .user-course {
      // 我的课程
      background-color: #fff;
      margin-top: 0.2rem;

      .box-card {
        .clearfix {
          display: flex;
          font-size: 0.18rem;
          font-family: Microsoft YaHei;
          font-weight: bold;
          color: #000000;
          line-height: 0.42rem;
        }
      }

      .course-cell {
        .course-name-p {
          display: flex;

          .course-name-span {
            margin-left: 0.13rem;
            font-size: 0.18rem;
            font-family: Adobe Heiti Std;
            font-weight: normal;
            color: #000000;
          }
        }

        .progress-box {
          padding: 0.1rem 0;

          .lose-time {
            margin-left: 0.5rem;
          }
        }

        .teacher-box {
          padding: 0.1rem 0;
          display: flex;
          flex-wrap: wrap;

          .teacher-card {
            width: 1rem;
            height: 1.2rem;
            margin-right: 0.05rem;
            margin-bottom: 0.05rem;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            .teacher-img {
              width: 0.5rem;
              height: 0.5rem;
              border-radius: 50%;
            }

            .teacher-name {
              text-align: center;
              font-size: 0.12rem;
              font-family: Hiragino Sans GB;
              font-weight: normal;
              color: #323232;
              margin-top: 0.09rem;
            }
          }
        }
      }
    }
  }
}
.fouter_bot{
  width: 100%;
  height: 160px;
}
</style>
