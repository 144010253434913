<template>
  <div class="main">
    <div class="bjc-player-wrapper">
      <video class="video video-js vjs-default-skin">
      </video>
    </div>
  </div>
</template>

<script>
export default {
  name: "onDemandVideo",
  data() {
    return {}
  },
  mounted() {
    console.log(this.$route.query,'>>>>>>>>>>>>>111222')
    // let instance = new BjcPlayer(domSelector, param);  // 初始化实例
    // let player = new BjcPlayer($('#main').find('.video')[0], {
    let player = new BjcPlayer(document.getElementsByClassName('video'), {
      // token: "cJ_IfPQ4OIRWRpkCf_sKaJQjHJd60qmfJq78w9MAbUR0U-mYN3AvOWOq-y7-VeBX",
      token: this.$route.query.token,
      // vid: "72483994",
      vid: this.$route.query.vid,
      playbackRates: [0.7, 1, 1.2, 1.5, 2], // 倍速 传入 [] 隐藏倍速列表
      privateDomainPrefix: '', // 专属域名前缀 0.0.2版本开始支持
      user_name: '用户名',  // 用户名， 主要用于数据统计
      user_number: '12345',  // 用户id， 主要用于数据统计
      onended: function (data) {
        console.log('onplayend event',data);
      },
      onplay: function () {
        console.log('onplaybegin event');
      },
      onpause: function () {
        console.log('onplaypause event');
      },
      ontimeupdate: function (data) {
        console.log('ontimeupdate event');
        console.log('currentTime:' + data.currentTime);
      },
      onseeked: function (data) {
        console.log('onseek event');
        console.log('seekTime:' + data.currentTime);
      },
      onerror: function (data) {
        console.log('onerror event');
        console.log(data);
      }
    });
  }
}
</script>

<style scoped lang="less">
.main{
  width: 100%;
  height:100%;
  background: #2c3e50;
}
.bjc-player-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.bjc-player-wrapper .video-js {
  width: 80%;
  height: 80%;
}
</style>