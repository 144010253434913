<template>
  <div class="video-page">
    <div class="index-page">
<!--    <div class="index-page">-->
      <div class="course-title">当前播放:{{ $route.query.sectionName }}</div>
      <div class="video-box" :style="adaptive === 'model' ? 'width:100%' : ''" :id="polyvId">
        <!-- polyv box -->
      </div>
    </div>
    <fouter class="fouter_bot" style="padding:0;"></fouter>
<!--    <fouter class="fouter_bot"></fouter>-->
  </div>
</template>

<script>
// @ is an alias to /src
import fouter from '../../src/components/fouter/fouter'
export default {
  name: "VideoPage",
  data() {
    return {
      msg: "VideoPage  录播视频",
      goodsList: [],
      //适配类型
      adaptive: ''
    };
  },
  computed: {
    polyvId() {
      return "pol_" + this.$route.query.v;
    }
  },
  methods: {
    initPolyv() {
      let ref = this;
      let player = window.polyvPlayer({
        wrap: "#" + ref.polyvId,
        width : '100%',
        height : '100%',
        code: ref.$route.query.c, //跑马灯设置中自定义的code值
        playsafe: ref.$route.query.s,
        vid: ref.$route.query.v,
        ts: ref.$route.query.ts,
        sign: ref.$route.query.sign
      });
      console.log('++++++++++++++++++++++++++')
      window.s2j_onPlayOver = function() {
      console.log(player.vid,":vid---");
        ref.finishCourse(ref.$route.query.sectionId);
      };
    },
    finishCourse(sectionId) {
      let ref = this;
      let paramData = {
        sectionId
      };
      ref.axios.post("/shop1Api/user/study/finish", paramData).then(res => {
        let data = res.data;
        if (data.success) {
          // data
          console.log("finish");
        }
      });
    }
  },
  // created() {},
  mounted() {
    this.adaptive = this.$store.state.adaptive
    let ref = this;
    ref.initPolyv();
  },
  components: {
    fouter
  }
};
</script>
<style lang="less" scoped>
.video-page {
  height: 100%;
  background: #2d313f;
  width: 100%;
  .index-page{
    width: 100%;
    background: #2d313f;
    box-sizing: border-box;
    padding-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    min-height: calc(100vh - 160px);
    //height: 90vh;
    padding-bottom: 20px;
    .course-title {
      line-height: 2;
      text-align: left;
      color: white;
    }
    .video-box {
      //max-height: 50vh;
      height: 90vh;
      //min-height: 90vh;
      position: relative;
      display: flex;
      align-items: center;
      width: 80%;
      //min-width: 30vw;
    }
  }
  .fouter_bot{
    width: 100%;
    height: 160px;
  }
}
</style>

