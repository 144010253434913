<template>
  <div
      class="pay-order"
      id="pay-order"
      v-loading.fullscreen="loading"
      element-loading-text="拼命加载中，请勿关闭页面"
  >
    <el-card :class="adaptive === 'Pc' ? 'box-card' : 'box-card-model'">
      <div slot="header" class="clearfix">
        <span>选择支付方式</span>
      </div>
      <div class="pay-class-box">
        <div class="ali-wx" :style="adaptive === 'model' ? 'flex-wrap:nowrap;' : ''">
          <!-- 支付宝 -->
          <el-card :body-style="adaptive === 'Pc' ? 'bodyStyle' : 'bodyStyle_model'" class="pay-card">
            <el-image
                :style="adaptive === 'Pc' ? 'width: 200px; height: 200px' : 'width: 2rem; height: 2rem'"
                :src="require('../assets/alipay.png')"
                fit="fill"
            ></el-image>
            <div style="text-align:center;">
              <!-- <span>支付宝</span> -->
              <div class="radil-box">
                <el-radio v-model="payMethod" label="1">支付宝</el-radio>
              </div>
            </div>
          </el-card>
          <!-- 微信 -->
          <el-card :body-style="adaptive === 'Pc' ? 'bodyStyle' : 'bodyStyle_model'" class="pay-card">
            <el-image
                :style="adaptive === 'Pc' ? 'width: 200px; height: 200px' : 'width: 2rem; height: 2rem'"
                :src="require('../assets/wechartpay.png')"
                fit="fill"
            ></el-image>
            <div style="text-align:center;">
              <!-- <span>微信</span> -->
              <div class="radil-box">
                <el-radio v-model="payMethod" label="2">微信</el-radio>
              </div>
            </div>
          </el-card>
        </div>
        <div
            style="padding-top:20px;display:flex;justify-content: flex-end;align-items: center;"
        >
          <span :style="adaptive === 'Pc' ? 'font-size:18px;margin-right:15px;' : 'font-size:0.18rem;margin-right:0.1rem;'">实付金额：</span>
          <span class="price-num">￥{{ $route.query.amount }}</span>
          <el-button class="pay-btn" size="small" type="primary" @click="goPay"
          >去支付
          </el-button
          >
        </div>
      </div>
      <div class="wechart-qr-box" v-if="!!wecahrtQrContent">
        <el-alert
            title="微信支付请扫描下方二维码,支付完成请点击下方 '我已支付' 按钮"
            type="warning"
            :closable="false"
            center
            show-icon
        ></el-alert>
        <vue-qr :text="wecahrtQrContent" :size="200"></vue-qr>
        <div>
          <el-button
              class="check-pay-btn"
              size="small"
              type="primary"
              @click="wxPayCheck"
          >我已支付
          </el-button
          >
        </div>
      </div>
    </el-card>
    <fouter class="fouter_bot"></fouter>
  </div>
</template>

<script>
// @ is an alias to /src
import vueQr from "vue-qr";
import fouter from '../../src/components/fouter/fouter'

export default {
  name: "PayOrder",
  data() {
    return {
      msg: "PayOrder  支付",
      loading: false,
      bodyStyle: {
        padding: "20px",
        width: "240px",
        textAlign: "center"
      },
      bodyStyle_model: {
        padding: "0.2rem",
        width: "2.4rem",
        textAlign: "center"
      },
      payMethod: "",
      loopTimes: 0,
      wecahrtQrContent: "", //二维码地址
      //适配类型
      adaptive: ''
    };
  },
  computed: {
    // amount() {
    //   return this.$route.query.amount;
    // }
  },
  methods: {
    wxPayCheck() {
      let ref = this;
      ref.loading = true;
      ref.getResultloop();
    },
    showConfirm() {
      let ref = this;
      let str =
          '<div class="confirm-con-costom"><i class="el-icon-question"></i><p class="message-info">请确认是否支付成功?</p></div>';
      this.$confirm(str, " ", {
        confirmButtonText: "支付成功进入课程",
        cancelButtonText: "支付失败重新支付",
        dangerouslyUseHTMLString: true,
        customClass: "pay-message-box",
        cancelButtonClass: "cancelButton",
        confirmButtonClass: 'cancelButton',
        showClose: false,
        center: true
        // type: 'info'
      })
          .then(() => {
            // 调起loading & 发起轮询
            ref.loading = true;
            ref.getResultloop();
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消,请重新发起支付"
            });
          });
    },
    goPay() {
      let ref = this;
      if (!ref.payMethod) {
        ref.$message({message: "请选择支付方式", type: "warning"});
        return false;
      }
      // 支付
      let payUrl =
          ref.payMethod === "1"
              ? "http://shop.21yan.com/api/ali/pay?requestId="
              : "http://shop.21yan.com/api/wx/pay?requestId=";
      if (ref.payMethod === "1") {
        // 阿里支付
        ref.showConfirm();
        window.open(payUrl + ref.$route.query.requestId);
      } else {
        // 微信支付
        let config = {
          params: {
            requestId: ref.$route.query.requestId,
            userId : ref.$store.state.userInfo.userId
          }
        };
        ref.axios.get("/shop1Api/wx/pay", config).then(res => {
          let data = res.data;
          if (data.success) {
            ref.wecahrtQrContent = data.entity;
            // 显示二维码之后展示开启轮询按钮(微信支付不再显示loading ，否则影响扫码)
          } else {
            ref.$message({message: data.message, type: "warning"});
          }
        });
      }
    },
    getResultloop() {
      // 获取当前分类的课程列表
      let ref = this;
      ref.loopTimes++;
      let paramData = {
        requestId: ref.$route.query.requestId,
        userId : ref.$store.state.userInfo.userId
      };
      ref.axios.post("/shop1Api/order/review", paramData).then(res => {
        let data = res.data;
        if (data.success) {
          // 订单支付成功 :去往订单中心页面
          ref.loading = false;
          this.$message({
            type: "success",
            message: "支付成功，前往订单中心"
          });
          ref.$router.push({
            path: "/order-center"
          });
        } else {
          // 订单支付失败
          if (ref.loopTimes < 15) {
            setTimeout(function () {
              ref.getResultloop();
            }, 500);
          } else {
            ref.loading = false;
            ref.$message({message: "支付校验失败", type: "error"});
          }
        }
      });
    }
  },
  components: {
    vueQr,
    fouter
  },
  created() {
    this.adaptive = this.$store.state.adaptive
    // this.showConfirm();
  }
};
</script>
<style lang="less" scoped>
.box-card {
  width: 1200px;
  margin: 0 auto;
  min-height: calc(100vh - 160px);

  .fouter_bot {
    width: 100%;
    height: 160px;
  }
}

.box-card-model {
  //width: 100%;
  margin: 0 auto;
  min-height: calc(100vh - 160px);

  .fouter_bot {
    width: 100%;
    height: 1.6rem;
  }
}

.pay-order {
  padding-top: 70px;

  .wechart-qr-box {
    padding-top: 30px;
  }

  .pay-class-box {
    .ali-wx {
      display: flex;
      flex-wrap: wrap;

      .pay-card {
        margin-right: 40px;
      }
    }

    span {
      display: inline-block;
      line-height: 1;

      &.price-num {
        color: red;
        margin-right: 23px;
        font-size: 24px;
        font-family: Hiragino Sans GB;
        font-weight: normal;
        color: #fb2626;
      }
    }

    .pay-btn {
      vertical-align: text-bottom;
      width: 150px;
      height: 40px;
      background: #ff1a00;
      border-radius: 5px;
      border: 0;
    }
  }
}
</style>
<style lang="less">
.pay-message-box {
  .confirm-con-costom {
    .el-icon-question {
      font-size: 80px;
      color: #ffcd20;
    }

    .message-info {
      padding: 30px 0;
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #000000;
    }
  }
}

.cancelButton {
  width: 150px;
  height: 46px;
  background: #2065ff !important;
  border-radius: 4px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: bolder;
  color: #ffffff !important;
  border: 0 !important;
}
</style>
